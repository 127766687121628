import {
  makeStyles,
  tokens,
  Card,
  CardHeader,
  Button,
  Tooltip,
  CardFooter,
  Spinner
} from '@fluentui/react-components';
import useToasts from '../../hooks/useToasts';
import {RemoveResourceGroupDocument} from '../../api/__generated__/graphql';
import {useAppSelector} from '../../store/hooks';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import {useMutation} from 'urql';

const useStyles = makeStyles({
  card: {
    paddingLeft: tokens.spacingHorizontalXXL,
    paddingTop: tokens.spacingVerticalXXL,
    paddingBottom: tokens.spacingVerticalXXL
  }
});

const RemoveResourceGroupCard = () => {
  const styles = useStyles();
  const {dispatchAppToast} = useToasts();
  const {organization} = usePopulatedTopbarValues();
  const {selectedResourceGroup} = useAppSelector(state => state.resourceGroupsSlice);
  const [{fetching}, removeResourceGroup] = useMutation(RemoveResourceGroupDocument);

  if (!organization) {
    return <></>;
  }

  const onClickRemove = () => {
    removeResourceGroup({
      input: {
        force: false,
        resourceGroupArn:
          'arn:resource-group:' + organization.id + ':' + selectedResourceGroup.id + ':'
      }
    }).then(result => {
      if (result.error) {
        dispatchAppToast({
          title: 'Failed to remove resource group',
          intent: 'error',
          message: result.error.message
        });
      } else if (result.data?.removeResourceGroup?.error) {
        dispatchAppToast({
          title: 'Failed to remove resource group',
          intent: 'error',
          message: result.data?.removeResourceGroup?.error
        });
      } else {
        dispatchAppToast({
          title: 'Resource group removed',
          intent: 'success',
          message: 'Removed resource group: ' + selectedResourceGroup.name
        });
      }
    });
  };

  return (
    <Card className={styles.card}>
      <CardHeader header={<b>Remove resource group</b>} />
      <CardFooter>
        {selectedResourceGroup.id === '' ? (
          <Tooltip content={'No resource group selected'} relationship={'label'}>
            <Button disabled={true}>Remove</Button>
          </Tooltip>
        ) : fetching ? (
          <Button>
            <Spinner size="tiny" />
          </Button>
        ) : (
          <Button onClick={onClickRemove}>Remove</Button>
        )}
      </CardFooter>
    </Card>
  );
};

export default RemoveResourceGroupCard;
