import {
  makeStyles,
  shorthands,
  tokens,
  Radio,
  RadioGroup,
  RadioOnChangeData
} from '@fluentui/react-components';

import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {
  createOrUpdateScenarioTemplate,
  setCommonConfiguration
} from '../../../../reducers/deviceDetailsSlice';
import {DeviceDetailsState} from '../../../../reducers/deviceDetailsSlice';
import {ScenarioType} from '../../../../types';

const useStyles = makeStyles({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.padding(tokens.spacingVerticalXL, 0)
  },
  buttonWrapper: {
    display: 'flex'
  },
  templateRadio: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '8rem',
    justifyContent: 'center',
    ...shorthands.margin(0, tokens.spacingHorizontalS),
    ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStroke1),
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
    textAlign: 'center',
    width: '15rem',
    '> div': {
      display: 'none'
    },
    '& label': {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'center',
      width: '100%',
      ...shorthands.padding(0, tokens.spacingHorizontalS)
    },
    '& input:enabled:checked ~ label': {
      ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStroke1Selected)
    }
  },
  templateTitle: {
    fontWeight: '600',
    ...shorthands.margin(0, 0, tokens.spacingVerticalS)
  }
});

const TemplateSelect = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const {
    commonConfiguration: {template},
    devicesAoADetails
  }: DeviceDetailsState = useAppSelector(state => state.deviceDetailsSlice);

  const onTemplateSelect = (_: unknown, data: RadioOnChangeData) => {
    dispatch(setCommonConfiguration({template: data.value}));
    devicesAoADetails.forEach(deviceAoADetails =>
      dispatch(createOrUpdateScenarioTemplate({serial: deviceAoADetails.serial, scenario: null}))
    );
  };

  return (
    <>
      <div className={styles.wrapper}>
        <h3>What would you like to create?</h3>
        <div className={styles.buttonWrapper}>
          <RadioGroup value={template || undefined} onChange={onTemplateSelect} layout="horizontal">
            <Radio
              value={ScenarioType.LINE_CROSSING}
              className={styles.templateRadio}
              label={
                <div>
                  <div className={styles.templateTitle}>Line crossing</div>
                  <div>Detect objects that cross a line</div>
                </div>
              }
            />
            <Radio
              value={ScenarioType.CROSSLINE_COUNTING}
              className={styles.templateRadio}
              label={
                <div>
                  <div className={styles.templateTitle}>Crossline counting</div>
                  <div>Count objects that cross a line</div>
                </div>
              }
            />
            <Radio
              value={ScenarioType.OCCUPANCY_IN_AREA}
              className={styles.templateRadio}
              label={
                <div>
                  <div className={styles.templateTitle}>Occupancy</div>
                  <div>Detect the number of objects within an area</div>
                </div>
              }
            />
            <Radio
              value={ScenarioType.MOTION_IN_AREA}
              className={styles.templateRadio}
              label={
                <div>
                  <div className={styles.templateTitle}>Motion in area</div>
                  <div>Detect movement inside an area</div>
                </div>
              }
            />
          </RadioGroup>
        </div>
      </div>
    </>
  );
};

export default TemplateSelect;
