import {makeStyles, shorthands, Spinner} from '@fluentui/react-components';

const useStyles = makeStyles({
  spinner: {
    height: '100%',
    ...shorthands.margin('auto')
  }
});

const Loader = () => {
  const styles = useStyles();
  return <Spinner className={styles.spinner} />;
};

export default Loader;
