import {useAuth} from '@axteams-one/auth-provider';
import {useState, useEffect} from 'react';

/** @deprecated use getAccessToken instead */
export const useToken = () => {
  const {refresh} = useAuth();
  const [token, setToken] = useState<string>();

  useEffect(() => {
    refresh()
      .then(res => setToken(res.data?.access_token))
      .catch(err => console.log('Failed to get acx token', err));
  }, [refresh]);

  return token;
};
