import {configureStore} from '@reduxjs/toolkit';
import {createLogger} from 'redux-logger';

import {rootReducer} from '../reducers';

const devTools = process.env.NODE_ENV !== 'production';

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    devTools
      ? getDefaultMiddleware({immutableCheck: false, serializableCheck: false}).concat(
          createLogger()
        )
      : getDefaultMiddleware({immutableCheck: false, serializableCheck: false}),
  devTools
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {devicesSlice: Devices, deviceDetailsSlice: DeviceAoADetails, errorsSlice: Errors}

export type AppDispatch = typeof store.dispatch;
export default store;
