import {shorthands, tokens, makeStyles, Button} from '@fluentui/react-components';
import {DeleteRegular} from '@fluentui/react-icons';

import arraysAreEqual from '../../../../../helpers/arraysAreEqual';
import {
  MotionInAreaScenario,
  Nullable,
  OccupancyInAreaScenario,
  FilterType,
  FilterExcludeArea,
  TriggerTypes
} from '../../../../../types';

interface Props {
  readonly scenario: MotionInAreaScenario | OccupancyInAreaScenario;
  readonly capFiltersExcludeAreaDefault?: Nullable<[number, number][]>;
  readonly capTriggersDefaultInstance: Nullable<[number, number][]>;
  readonly onUpdate: (scenario: MotionInAreaScenario | OccupancyInAreaScenario) => void;
}

const useStyles = makeStyles({
  settingsContent: {
    ...shorthands.padding(0, 0, 0, tokens.spacingHorizontalL),
    ...shorthands.margin(0, 0, tokens.spacingVerticalM)
  },
  excludeAreaWrapper: {
    ...shorthands.margin(tokens.spacingVerticalXL, 0)
  },
  subWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    ...shorthands.gap(tokens.spacingHorizontalM),
    ...shorthands.margin(0, tokens.spacingHorizontalM, tokens.spacingVerticalM, 0)
  },
  removeButton: {
    alignItems: 'center',
    display: 'flex',
    ...shorthands.padding(0, 0, 0, tokens.spacingHorizontalM),
    ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStroke1),
    ...shorthands.borderRadius(tokens.borderRadiusMedium)
  }
});

const AreaofInterest = ({
  scenario,
  capFiltersExcludeAreaDefault,
  capTriggersDefaultInstance,
  onUpdate
}: Props) => {
  const styles = useStyles();
  const excludeAreas = scenario.filters.flatMap(item =>
    item.type === TriggerTypes.EXCLUDE_AREA ? [item] : []
  );

  const onAddExcludeAreaHandler = () => {
    if (!capFiltersExcludeAreaDefault) return;

    const newExcludeArea: FilterExcludeArea = {
      type: TriggerTypes.EXCLUDE_AREA,
      vertices: capFiltersExcludeAreaDefault
    };
    onUpdate({
      ...scenario,
      filters: [...scenario.filters, newExcludeArea]
    });
  };

  const onRemoveExcludeArea = (excludeAreaVertices: FilterExcludeArea['vertices']) => {
    const newFilters = scenario.filters.filter(
      (filter: FilterType) =>
        !(
          filter.type === TriggerTypes.EXCLUDE_AREA &&
          arraysAreEqual(filter.vertices, excludeAreaVertices)
        )
    );

    onUpdate({
      ...scenario,
      filters: newFilters
    });
  };

  const onResetIncludeArea = () => {
    if (capTriggersDefaultInstance) {
      onUpdate({
        ...scenario,
        triggers: [
          {
            type: TriggerTypes.INCLUDE_AREA,
            vertices: capTriggersDefaultInstance
          }
        ]
      });
    }
  };

  return (
    <div>
      <h3>Area of interest</h3>
      <div className={styles.settingsContent}>
        <p>
          Adjust the include area to cover the part of the scene that you are interested in. To
          exclude parts of the area, you can create exclude areas.
        </p>
      </div>
      <div className={styles.settingsContent}>
        <div className={styles.excludeAreaWrapper}>
          <div className={styles.subWrapper}>
            {excludeAreas.map((excludeArea, index) => (
              <div className={styles.removeButton} key={index}>
                {`Exclude area ${index + 1}`}
                <Button
                  key={index}
                  appearance="transparent"
                  icon={<DeleteRegular />}
                  iconPosition="after"
                  onClick={() => onRemoveExcludeArea(excludeArea.vertices)}
                />
              </div>
            ))}
          </div>
          <div className={styles.subWrapper}>
            <Button appearance="primary" onClick={onAddExcludeAreaHandler}>
              Add exclude area
            </Button>
            <Button appearance="primary" onClick={onResetIncludeArea}>
              Reset include area
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaofInterest;
