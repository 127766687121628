import {
  Checkbox,
  CheckboxOnChangeData,
  makeStyles,
  shorthands,
  tokens
} from '@fluentui/react-components';

import {setCommonConfiguration} from '../../../../reducers/deviceDetailsSlice';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {ObjectClassificationType} from '../../../../types';

const useStyles = makeStyles({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.padding(tokens.spacingVerticalXL, 0)
  },
  checkboxWrapper: {
    display: 'flex'
  },
  checkbox: {
    fontWeight: '600'
  }
});

const TriggeringObjectsSelect = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const {
    commonConfiguration: {objectClassifications}
  } = useAppSelector(state => state.deviceDetailsSlice);

  const selectTriggeringObjects = (
    e: React.ChangeEvent<HTMLInputElement>,
    data: CheckboxOnChangeData
  ) => {
    const newObjectClassifications = objectClassifications.filter(
      classification => classification.type !== e.target.value
    );
    if (data.checked) {
      newObjectClassifications.push({type: e.target.value as ObjectClassificationType});
    }
    dispatch(setCommonConfiguration({objectClassifications: newObjectClassifications}));
  };

  return (
    <>
      <div className={styles.wrapper}>
        <h3>Select the objects to be detected.</h3>
        <div className={styles.checkboxWrapper}>
          <Checkbox
            value="human"
            className={styles.checkbox}
            label="Human"
            checked={objectClassifications.some(object => object.type === 'human')}
            onChange={selectTriggeringObjects}
          />
          <Checkbox
            value="vehicle"
            className={styles.checkbox}
            label="Vehicle"
            checked={objectClassifications.some(object => object.type === 'vehicle')}
            onChange={selectTriggeringObjects}
          />
        </div>
      </div>
    </>
  );
};

export default TriggeringObjectsSelect;
