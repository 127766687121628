import {makeStyles, tokens, shorthands, mergeClasses} from '@fluentui/react-components';
import {useCallback} from 'react';

import {
  AoACapabilities,
  LineCrossingAoAScenario,
  TriggerLineCrossing,
  AoAScenario,
  LineDirection
} from '../../../../../types';
import ScenarioName from '../ScenarioName';
import VideoWrapper from '../VideoWrapper';
import VirtualLine from '../VirtualLine';

interface Props {
  readonly deviceImg: string;
  readonly scenario: LineCrossingAoAScenario;
  readonly deviceCapabilities: AoACapabilities;
  readonly updateScenarioConfiguration: (scenario: AoAScenario) => void;
}

const useStyles = makeStyles({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  configWrapper: {
    display: 'flex',
    justifyContent: 'center',
    ...shorthands.padding(tokens.spacingHorizontalXL, 0)
  },
  settingsWrapper: {
    ...shorthands.padding(0, tokens.spacingHorizontalXL),
    width: '45rem'
  },
  fullWidth: {
    width: '100%'
  }
});

const LineCrossingScenarioTemplate = ({
  deviceImg,
  scenario,
  deviceCapabilities,
  updateScenarioConfiguration
}: Props) => {
  const styles = useStyles();

  const {triggers: capTriggers, scenarios: capScenarios} = deviceCapabilities;

  const changeTriggerDirectionHandler = useCallback(() => {
    const trigger: TriggerLineCrossing = scenario.triggers[0];

    updateScenarioConfiguration({
      ...scenario,
      triggers: [
        {
          ...trigger,
          alarmDirection:
            trigger?.alarmDirection === LineDirection.LTR ? LineDirection.RTL : LineDirection.LTR
        }
      ]
    });
  }, [scenario, updateScenarioConfiguration]);

  const onResetLineHandler = () => {
    if (
      !capTriggers.lineCrossing.defaultAlarmDirection ||
      !capTriggers.lineCrossing.defaultInstance
    )
      return;

    updateScenarioConfiguration({
      ...scenario,
      triggers: [
        {
          ...scenario.triggers[0],
          vertices: capTriggers.lineCrossing.defaultInstance,
          alarmDirection: capTriggers.lineCrossing.defaultAlarmDirection
        }
      ]
    });
  };

  return (
    <div className={mergeClasses(styles.wrapper, styles.fullWidth)}>
      <ScenarioName
        minLengthName={capScenarios.minLengthName || undefined}
        maxLengthName={capScenarios.maxLengthName || undefined}
        currentScenarioName={scenario.name}
        onUpdate={name => updateScenarioConfiguration({...scenario, name})}
      />
      <div className={mergeClasses(styles.configWrapper, styles.fullWidth)}>
        <VideoWrapper
          deviceImg={deviceImg}
          scenario={scenario}
          capTriggerVertices={capTriggers.lineCrossing}
          onUpdate={scenario => 'id' in scenario && updateScenarioConfiguration(scenario)}
        />
        <div className={styles.settingsWrapper}>
          <VirtualLine
            changeTriggerDirection={changeTriggerDirectionHandler}
            onResetLine={onResetLineHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default LineCrossingScenarioTemplate;
