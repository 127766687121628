import {Button} from '@fluentui/react-button';
import {Parser, processors} from 'xml2js';
import {useEffect, useState} from 'react';
import {Routes, Route, Outlet, useNavigate} from 'react-router-dom';
import {OrganizationInfo, usePopulatedTopbarValues} from '@axteams-one/populated-topbar';

import {
  RuleCondition,
  addEventRule,
  resetDeviceCommonDetails,
  setCommonConfiguration
} from '../../../reducers/deviceDetailsSlice';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import Panel from '../../../components/Panel';
import ScenarioConfiguration from './ScenarioConfiguration';
import TemplateSelect from './TemplateSelect';
import TriggeringObjectsSelect from './TriggeringObjectsSelect';
import {Nullable, ScenarioType} from '../../../types';
import SetEvents from './SetEvents';
import {SetActionRuleSoapTemplate, SetActionSoaptemplate} from './SetEvents/soapTemplates';
import {useToken} from '../../../hooks/useToken';
import {soapServices} from '../../../fetcher';

interface ConfigureDevicesPanelProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

const configurationSteps = (template: Nullable<ScenarioType>) => {
  switch (template) {
    case ScenarioType.MOTION_IN_AREA: {
      return ['/configuration/selectTemplate', '/configuration/scenarioConfiguration'];
    }
    default: {
      return [
        '/configuration/selectTemplate',
        '/configuration/selectTriggeringObjects',
        '/configuration/scenarioConfiguration'
      ];
    }
  }
};

const ConfigureDevicesPanel = ({isOpen, onClose}: ConfigureDevicesPanelProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = useToken();
  const {organization} = usePopulatedTopbarValues();

  const {
    commonConfiguration: {template, objectClassifications},
    devicesGeneralDetails,
    eventRule
  } = useAppSelector(state => state.deviceDetailsSlice);

  const [step, setStep] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setStep(0);
    }
  }, [isOpen]);

  useEffect(() => {
    const isConfigurationInprogress = devicesGeneralDetails.some(
      device => device?.newScenarioTemplate
    );
    if (
      (step === 0 && !template) ||
      (template !== ScenarioType.MOTION_IN_AREA && step === 1 && !objectClassifications.length) ||
      (step === configurationSteps(template).length - 1 && isConfigurationInprogress)
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [step, objectClassifications, devicesGeneralDetails, template]);

  const onContinue = () => {
    setStep(prev => prev + 1);
    navigate(configurationSteps(template)[step + 1]);
  };

  const onBack = () => {
    setStep(prev => prev - 1);
    navigate(configurationSteps(template)[step - 1]);
  };

  const onCancel = () => {
    dispatch(resetDeviceCommonDetails());
    navigate('/configuration');
    onClose();
  };

  const validateEventRules = () => {
    const isValid = Object.values(eventRule).every(rule => rule !== '');
    dispatch(addEventRule({isValid}));
    if (!isValid) {
      return;
    }

    if (token && organization) {
      const selectedConditions = eventRule.ruleConditions.filter(condition => condition.selected);

      selectedConditions.forEach(ruleCondition => {
        executeSoapServices(token, organization, ruleCondition);
        dispatch(setCommonConfiguration({template: null}));
        navigate('/configuration');
        onClose();
      });
    }
  };

  const executeSoapServices = async (
    token: string,
    organization: OrganizationInfo,
    ruleCondition: RuleCondition
  ) => {
    const name = ruleCondition.name;
    const conditionName = name.replaceAll(' ', '');
    const setActionSoapTemplate = SetActionSoaptemplate(
      eventRule.overlayText,
      eventRule.videoChannel
    );
    const setActionSoapTemplateResponse = await soapServices(
      token,
      organization?.arn,
      ruleCondition.serial,
      setActionSoapTemplate
    );
    const parser = new Parser({
      tagNameProcessors: [processors.stripPrefix, processors.normalize]
    });
    const result = await parser.parseStringPromise(setActionSoapTemplateResponse as string);

    const addActionConfigurationResponse =
      result['envelope']['body'][0]['addactionconfigurationresponse'];
    const configurationId = addActionConfigurationResponse[0]['configurationid'][0];

    const setActionRuleSoapTemplate = SetActionRuleSoapTemplate(
      configurationId,
      eventRule.ruleName,
      conditionName,
      eventRule.waitTime
    );
    await soapServices(token, organization?.arn, ruleCondition.serial, setActionRuleSoapTemplate);
  };

  const onFinish = () => {
    validateEventRules();
  };

  return (
    <Panel
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      title="Configure scenario"
      footer={
        <>
          <Button appearance="transparent" onClick={onCancel}>
            Cancel
          </Button>
          {step !== 0 && step <= configurationSteps(template).length - 1 && (
            <Button onClick={onBack} disabled={!template}>
              Back
            </Button>
          )}
          {step === configurationSteps(template).length - 1 ? (
            <Button appearance="primary" onClick={onFinish} disabled={isDisabled}>
              Finish
            </Button>
          ) : (
            <Button appearance="primary" onClick={onContinue} disabled={isDisabled}>
              Continue
            </Button>
          )}
        </>
      }
    >
      <Routes>
        <Route>
          <Route path="selectTemplate" element={<TemplateSelect />} />
          <Route path="selectTriggeringObjects" element={<TriggeringObjectsSelect />} />
          <Route path="scenarioConfiguration" element={<ScenarioConfiguration />} />
          <Route path="setEvents" element={<SetEvents />} />
        </Route>
      </Routes>
      <Outlet />
    </Panel>
  );
};

export default ConfigureDevicesPanel;
