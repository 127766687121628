import {
  makeStyles,
  tokens,
  Card,
  CardHeader,
  Input,
  Button,
  Tooltip,
  Field,
  CardFooter,
  Spinner
} from '@fluentui/react-components';
import useToasts from '../../hooks/useToasts';
import {RegisterAxisDeviceDocument} from '../../api/__generated__/graphql';
import {useAppSelector} from '../../store/hooks';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import {useMutation} from 'urql';
import {useState} from 'react';

const useStyles = makeStyles({
  card: {
    paddingLeft: tokens.spacingHorizontalXXL,
    paddingTop: tokens.spacingVerticalXXL,
    paddingBottom: tokens.spacingVerticalXXL
  },
  field: {
    width: '20em'
  }
});

/* Device profiles that don't factory default the device.
   Will install our data aggregator ACAP.
   The onboarding templates can be found here (ctrl-f for Data Insights Dashboard):
   https://confluence.se.axis.com/pages/viewpage.action?spaceKey=SP&title=Stage+-+Configuration+templates */
const defaultDeviceProfile = () => {
  if (process.env.REACT_APP_ENV === 'prod') {
    return '6b70efc1-7375-426a-ab5b-4e34b05ad736';
  } else {
    return 'f58d794f-68bb-4118-957e-b652241ddd32';
  }
};

const OnboardingCard = () => {
  const styles = useStyles();
  const {dispatchAppToast} = useToasts();
  const {organization} = usePopulatedTopbarValues();
  const {selectedResourceGroup} = useAppSelector(state => state.resourceGroupsSlice);
  const [oak, setOak] = useState('');
  const [serial, setSerial] = useState('');
  const [name, setName] = useState('');
  const [{fetching}, onboardDevice] = useMutation(RegisterAxisDeviceDocument);

  if (!organization) {
    return <></>;
  }

  const onClickOnboard = () => {
    onboardDevice({
      input: {
        description: 'Onboarded in Data Insights Dashboard',
        deviceArn: undefined,
        deviceProfile: 'arn:device-profile:::' + defaultDeviceProfile(),
        name: name === '' ? undefined : name,
        oak: oak,
        resourceGroupArn: 'arn:resource-group:' + organization.id + '/' + selectedResourceGroup.id,
        serial: serial,
        timeZone: undefined
      }
    }).then(result => {
      if (result.error) {
        dispatchAppToast({
          title: 'Failed to start onboarding',
          intent: 'error',
          message: result.error.message
        });
      } else {
        dispatchAppToast({
          title: 'Onboarding started',
          intent: 'success',
          message: 'Onboarding has started for ' + name === '' ? serial : name
        });
      }
    });
  };

  return (
    <Card className={styles.card}>
      <CardHeader header={<b>Onboard device</b>} />
      <Field className={styles.field} label="OAK" orientation="horizontal" required={true}>
        <Input onChange={e => setOak(e.target.value)} />
      </Field>
      <Field className={styles.field} label="Serial" orientation="horizontal" required={true}>
        <Input onChange={e => setSerial(e.target.value)} />
      </Field>
      <Field className={styles.field} label="Name" orientation="horizontal">
        <Input onChange={e => setName(e.target.value)} />
      </Field>
      <CardFooter>
        {selectedResourceGroup.id === '' ? (
          <Tooltip content={'No resource group selected'} relationship={'label'}>
            <Button disabled={true}>Onboard</Button>
          </Tooltip>
        ) : fetching ? (
          <Button>
            <Spinner size="tiny" />
          </Button>
        ) : (
          <Button onClick={onClickOnboard}>Onboard</Button>
        )}
      </CardFooter>
    </Card>
  );
};

export default OnboardingCard;
