import React from 'react';
import ReactDOM from 'react-dom/client';
import {AuthProvider} from '@axteams-one/auth-provider';
import {Provider as StoreProvider} from 'react-redux';

import reportWebVitals from './reportWebVitals';
import {FluentProviderWrapper} from './FluentProviderWrapper';
import {App} from './App';
import './index.css';
import {store} from './store';
import {appConfig} from './config';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (process.env.REACT_APP_DISABLE_STRICT_MODE) {
  root.render(
    <AuthProvider
      env={appConfig.env !== 'dev' ? appConfig.env : 'stage'}
      localDevelopment={appConfig.env !== 'prod'}
    >
      <FluentProviderWrapper>
        <StoreProvider store={store}>
          <App />
        </StoreProvider>
      </FluentProviderWrapper>
    </AuthProvider>
  );
} else {
  root.render(
    <React.StrictMode>
      <AuthProvider
        env={appConfig.env !== 'dev' ? appConfig.env : 'stage'}
        localDevelopment={appConfig.env !== 'prod'}
      >
        <FluentProviderWrapper>
          <StoreProvider store={store}>
            <App />
          </StoreProvider>
        </FluentProviderWrapper>
      </AuthProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
