import {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router';
import {useQuery} from 'urql';
import {Button, makeStyles, shorthands, tokens} from '@fluentui/react-components';

import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';

import DevicesListView from './DevicesListView';
import DevicePanel from './DevicePanel';
import DevicesGridView from './DevicesGridView';
import ViewOptions from './ViewOptions';
import PageHeader from '../../components/PageHeader';
import {Acap, AllDevicesDocument, AxisDevice} from '../../api/__generated__/graphql';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import Loader from '../Loader';
import useToasts from '../../hooks/useToasts';
import {useAuth} from '@axteams-one/auth-provider';
import ConfigureDevicesPanel from './ConfigureDevicesPanel';
import {fetchImg} from '../../fetcher';
import {useToken} from '../../hooks/useToken';
import {addSelectedDevices, fetchDevices} from '../../reducers/devicesSlice';
import {resetDevicesDetails} from '../../reducers/deviceDetailsSlice';
import {addDeviceDetails} from '../../reducers/deviceDetailsSlice';
import {ACAPS} from '../../types';
import PageContent from '../../components/PageContent';

const useStyles = makeStyles({
  footer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    ...shorthands.padding(tokens.spacingVerticalM)
  }
});

const Devices = () => {
  const {status: authStatus} = useAuth();
  const {dispatchAppToast} = useToasts();
  const token = useToken();

  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {organization} = usePopulatedTopbarValues();
  const {selectedDevices, allDevices, status, error} = useAppSelector(state => state.devicesSlice);

  const [openSidebarSerial, setOpenSidebarSerial] = useState<string | boolean>();
  const [isOpenConfigurePanel, setIsOpenConfigurePanel] = useState(false);
  const [isListview, setisListView] = useState(true);
  const {selectedResourceGroup} = useAppSelector(state => state.resourceGroupsSlice);

  const [deviceList] = useQuery({
    pause: authStatus > 0 || !organization?.arn,
    query: AllDevicesDocument,
    variables: {organizationArn: organization?.arn || ''}
  });

  const filteredDevices = useMemo(
    () =>
      allDevices.filter(
        device =>
          selectedResourceGroup.id === '' ||
          (device.arn && device.arn.includes(selectedResourceGroup.id))
      ),
    [allDevices, selectedResourceGroup]
  );

  const getGeneralInfoForAllDevices = useCallback(
    (devices: Partial<AxisDevice>[]) => {
      const fetchPromises = devices.map(async device => {
        const aiSupported =
          device.acaps?.find(
            (acap: Acap) => acap.name === ACAPS.AOA || acap.name === ACAPS.AOA_BETA
          )?.status === 'RUNNING';

        if (token && organization?.arn) {
          if (device?.serial) {
            const response = await fetchImg(token, organization.arn, device.serial);
            return {
              serial: device.serial,
              img: response,
              aiSupported
            };
          }
        }
      });
      const devicesProps = Promise.all(fetchPromises);
      return devicesProps;
    },
    [token, organization]
  );

  const devicesSelectHandler = useCallback(
    (selectedDevicesList: Partial<AxisDevice>[]) => {
      if (!selectedDevicesList.length) {
        dispatch(resetDevicesDetails());
      }
      dispatch(addSelectedDevices(selectedDevicesList));
    },
    [dispatch]
  );

  const onClickConfigure = () => {
    setIsOpenConfigurePanel(true);
    navigate('/configuration/selectTemplate');
  };

  const onClosePanel = (panel: string) => {
    if (panel === 'configuration') {
      setIsOpenConfigurePanel(false);
      navigate('/configuration');
    } else {
      setOpenSidebarSerial(false);
    }
  };

  useEffect(() => {
    if (error) {
      dispatchAppToast({title: 'error', intent: 'error', message: error});
    }
  }, [error, dispatchAppToast]);

  // Fetch devices
  useEffect(() => {
    dispatch(fetchDevices(deviceList));
  }, [deviceList, dispatch]);

  useEffect(() => {
    if (allDevices.length) {
      getGeneralInfoForAllDevices(allDevices).then(res => dispatch(addDeviceDetails(res)));
    }
  }, [allDevices, getGeneralInfoForAllDevices, dispatch]);

  if (status !== 'succeeded') {
    return <Loader />;
  }

  return (
    <PageContent showResourceGroupTree>
      <PageHeader title="Devices" />
      <ViewOptions isListView={isListview} onChangeView={setisListView} />
      <DevicePanel
        device={allDevices.find(device => device.serial === openSidebarSerial)}
        isOpen={!!openSidebarSerial}
        onClose={() => onClosePanel('device')}
      />
      <ConfigureDevicesPanel
        isOpen={isOpenConfigurePanel}
        onClose={() => onClosePanel('configuration')}
      />
      {isListview ? (
        <DevicesListView
          devices={filteredDevices}
          selectedDevices={selectedDevices}
          devicesSelectHandler={devicesSelectHandler}
          setOpenSidebar={setOpenSidebarSerial}
        />
      ) : (
        <DevicesGridView
          devices={filteredDevices}
          selectedDevices={selectedDevices}
          devicesSelectHandler={devicesSelectHandler}
          setOpenSidebar={setOpenSidebarSerial}
        />
      )}
      <div className={styles.footer}>
        <Button appearance="primary" disabled={!selectedDevices.length} onClick={onClickConfigure}>
          Configure
        </Button>
      </div>
    </PageContent>
  );
};

export default Devices;
