import {
  ToastTitle,
  Toast,
  ToastTrigger,
  Link,
  ToastBody,
  useToastController
} from '@fluentui/react-components';
import {useCallback} from 'react';

interface ToastProps {
  readonly title: string;
  readonly intent: 'info' | 'success' | 'error' | 'warning';
  readonly message: string;
  readonly dismissable?: boolean;
}

export const defaultToasterId = 'toaster';

const useToasts = () => {
  const {dispatchToast} = useToastController(defaultToasterId);

  const dispatchAppToast = useCallback(
    ({title, message, intent, dismissable = true}: ToastProps) => {
      dispatchToast(
        <Toast>
          <ToastTitle
            {...(dismissable && {
              action: <Dismiss />
            })}
          >
            {title}
          </ToastTitle>
          <ToastBody>{message}</ToastBody>
        </Toast>,
        {intent: intent}
      );
    },
    [dispatchToast]
  );

  return {dispatchAppToast};
};

const Dismiss = () => (
  <ToastTrigger>
    <Link>Dismiss</Link>
  </ToastTrigger>
);

export default useToasts;
