import {
  Button,
  OverlayDrawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerHeaderTitle,
  makeStyles,
  tokens,
  shorthands,
  Card,
  Subtitle2,
  Body1Strong
} from '@fluentui/react-components';
import {Dismiss24Regular} from '@fluentui/react-icons';
import React from 'react';

interface PanelProps {
  readonly size?: 'medium' | 'large' | 'full';
  readonly title?: string;
  readonly subtitle?: string;
  readonly titleIcon?: JSX.Element;
  readonly isOpen: boolean;
  readonly footer?: React.ReactNode;
  readonly onClose: () => void;
  readonly dataTestId?: string;
}

const useStyles = makeStyles({
  drawerFooter: {
    justifyContent: 'flex-end',
    '> button': {
      ...shorthands.margin(0, 0, 0, tokens.spacingVerticalM)
    }
  },
  subTitle: {
    color: tokens.colorNeutralForeground3
  },
  titleDiv: {
    display: 'flex',
    flexDirection: 'column'
  }
});

const Panel = ({
  size = 'medium',
  title,
  subtitle,
  titleIcon,
  isOpen,
  children,
  onClose,
  footer,
  dataTestId
}: React.PropsWithChildren<PanelProps>) => {
  const styles = useStyles();

  return (
    <OverlayDrawer
      data-testid={dataTestId}
      modalType="non-modal"
      size={size}
      open={isOpen}
      position="end"
      onOpenChange={onClose}
    >
      <DrawerHeader data-testid="data-source-header-title">
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={onClose}
              data-testid="data-source-panel-close-button"
            />
          }
        >
          <Card size="small" appearance="subtle" orientation="horizontal">
            {titleIcon}
            <div className={styles.titleDiv}>
              <Subtitle2>{title}</Subtitle2>
              <Body1Strong className={styles.subTitle}>{subtitle}</Body1Strong>
            </div>
          </Card>
        </DrawerHeaderTitle>
      </DrawerHeader>
      <DrawerBody>{children}</DrawerBody>
      {footer && <DrawerFooter className={styles.drawerFooter}>{footer}</DrawerFooter>}
    </OverlayDrawer>
  );
};

export default Panel;
