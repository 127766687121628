import {makeStyles, shorthands, tokens, ToggleButton} from '@fluentui/react-components';
import {List20Filled, Grid20Filled} from '@fluentui/react-icons';

interface Props {
  readonly isListView: boolean;
  readonly onChangeView: (value: React.SetStateAction<boolean>) => void;
}

const useStyles = makeStyles({
  wrapper: {
    alignItems: 'center',
    columnGap: tokens.spacingHorizontalS,
    display: 'flex',
    justifyContent: 'flex-end',
    ...shorthands.padding(tokens.spacingHorizontalS)
  }
});

const ViewOptions = ({isListView, onChangeView}: Props) => {
  const styles = useStyles();
  return (
    <nav className={styles.wrapper}>
      <ToggleButton
        appearance="subtle"
        checked={isListView}
        onClick={() => onChangeView(oldValue => !oldValue)}
        icon={<List20Filled />}
      >
        List view
      </ToggleButton>
      <ToggleButton
        appearance="subtle"
        checked={!isListView}
        onClick={() => onChangeView(oldValue => !oldValue)}
        icon={<Grid20Filled />}
      >
        Grid view
      </ToggleButton>
    </nav>
  );
};

export default ViewOptions;
