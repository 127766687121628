import {
  makeStyles,
  shorthands,
  tokens,
  Image,
  Badge,
  Card,
  Checkbox,
  Link
} from '@fluentui/react-components';
import {AxisDevice} from '../../../../api/__generated__/graphql';

const useStyles = makeStyles({
  deviceCard: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.padding(tokens.spacingHorizontalXS)
  },
  deviceCardFooter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    ...shorthands.padding(tokens.spacingVerticalS, 0)
  }
});

export interface RegisteredDevices extends Partial<AxisDevice> {
  deviceImg: string;
}

interface DeviceCardProps {
  readonly device: RegisteredDevices;
  readonly isSelected: boolean;
  readonly onDeviceSelect: (state: boolean, data: RegisteredDevices) => void;
  readonly setOpenSidebar: (serial: string) => void;
}

const DeviceCard = ({device, isSelected, onDeviceSelect, setOpenSidebar}: DeviceCardProps) => {
  const styles = useStyles();

  const onDeviceCardSelect = () => onDeviceSelect(!isSelected, device);

  return (
    <Card
      floatingAction={<Checkbox onChange={onDeviceCardSelect} checked={isSelected} />}
      selected={isSelected}
      onSelectionChange={onDeviceCardSelect}
    >
      <Image src={device.deviceImg} />
      <div className={styles.deviceCardFooter}>
        <div>
          {device.model?.name} |{' '}
          <Link onClick={() => device.serial && setOpenSidebar(device.serial)}>
            {device.serial}
          </Link>
        </div>
        <Badge color="success" />
      </div>
    </Card>
  );
};

export default DeviceCard;
