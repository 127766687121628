import {makeStyles, tokens} from '@fluentui/react-components';

export const useDataGridStyles = makeStyles({
  header: {
    color: tokens.colorNeutralForeground3
  },
  root: {
    paddingTop: tokens.spacingHorizontalL
  },
  row: {
    ':active': {
      backgroundColor: 'none'
    }
  }
});
