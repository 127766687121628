// Temporary soap templates for setting test event rules.

export const SetActionSoaptemplate = (overlayText: string, videoChannel: string) =>
  `<?xml version="1.0" encoding="UTF-8"?>
<s:Envelope xmlns:s="http://www.w3.org/2003/05/soap-envelope" xmlns="http://www.axis.com/vapix/ws/action1">
 <s:Header/>
 <s:Body>
  <AddActionConfiguration xmlns="http://www.axis.com/vapix/ws/action1">
   <NewActionConfiguration>
    <Name>Use overlay text while the rule is active</Name>
    <TemplateToken>com.axis.action.unlimited.set_overlay</TemplateToken>
    <Parameters>
     <Parameter Name="text" Value="${overlayText}"/>
     <Parameter Name="channels" Value="${videoChannel}"/>
    </Parameters>
   </NewActionConfiguration>
  </AddActionConfiguration>
 </s:Body>
</s:Envelope>`;

//ConditionName for exact created scenario is not used as we are restricting the scenario creation. As a workaround ScenarioANY is being used.
export const SetActionRuleSoapTemplate = (
  actionId: string,
  ruleName: string,
  conditionName: string,
  waitTime: string
) => `<?xml version="1.0" encoding="UTF-8"?>
<s:Envelope xmlns:s="http://www.w3.org/2003/05/soap-envelope">
 <s:Header/>
 <s:Body xmlns="http://www.axis.com/vapix/ws/action1" xmlns:aev="http://www.axis.com/vapix/ws/event1" xmlns:wsnt="http://docs.oasis-open.org/wsn/b-2" xmlns:tns1="http://www.onvif.org/ver10/topics" xmlns:tnsaxis="http://www.axis.com/2009/event/topics">
  <AddActionRule xmlns="http://www.axis.com/vapix/ws/action1">
   <NewActionRule>
    <Name>${ruleName}</Name>
    <Enabled>true</Enabled>
    <!-- No start event -->
    <Conditions>
     <Condition>
      <TopicExpression Dialect="http://docs.oasis-open.org/wsn/t-1/TopicExpression/Concrete" xmlns="http://docs.oasis-open.org/wsn/b-2">tnsaxis:CameraApplicationPlatform/ObjectAnalytics/Device1ScenarioANY</TopicExpression>
      <MessageContent Dialect="http://www.onvif.org/ver10/tev/messageContentFilter/ItemFilter" xmlns="http://docs.oasis-open.org/wsn/b-2">boolean(//SimpleItem[@Name="active" and @Value="1"])</MessageContent>
     </Condition>
    </Conditions>
    <ActivationTimeout>PT${waitTime}S</ActivationTimeout>
    <PrimaryAction>${actionId}</PrimaryAction>
   </NewActionRule>
  </AddActionRule>
 </s:Body>
</s:Envelope>`;
