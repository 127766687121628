import {useContext} from 'react';
import {FoundationContext, LinerContext, withMidPoints, CoordArray} from 'media-overlay-library';

import translateFlexStructure from '../../../helpers/translateFlexStructure';

interface FlexStructureProps {
  readonly points: CoordArray;
  readonly onUpdate: (arg0: CoordArray) => void;
  readonly minNumberOfPoints: number;
  readonly maxNumberOfPoints: number;
  readonly children: (arg0: FlexStructureRenderProps) => JSX.Element;
}

export interface FlexStructureRenderProps {
  readonly allPoints: CoordArray;
  readonly extendible: boolean;
  readonly translate: ReturnType<typeof translateFlexStructure>;
  readonly handleRemovePoint: (pointId: number) => void;
  readonly handleDragEnd: (allDraggedPoints: CoordArray, id: unknown) => void;
}

const FlexStructure = ({
  points,
  onUpdate,
  minNumberOfPoints,
  maxNumberOfPoints,
  children
}: FlexStructureProps) => {
  const {toSvgBasis, toUserBasis} = useContext(FoundationContext);
  const {clampCoord, clampCoordArray} = useContext(LinerContext);
  const translate = translateFlexStructure(clampCoord, clampCoordArray);

  // Provide mid-points between points to allow expansion
  const allPoints = withMidPoints(points.map(toSvgBasis));
  const extendible = points.length < maxNumberOfPoints;

  const handleDragEnd = (allDraggedPoints: CoordArray, id: unknown) => {
    // Generate a new polygon by filtering out all midpoints except
    // if a midpoint was dragged.
    const userPoints = allDraggedPoints.filter((_, index) => index % 2 === 0 || id === index);
    onUpdate(userPoints.map(toUserBasis));
  };

  const handleRemovePoint = (pointId: number) => {
    if (allPoints.length / 2 > minNumberOfPoints) {
      const userPoints = allPoints.filter((_, index) => index % 2 === 0 && pointId !== index);
      onUpdate(userPoints.map(toUserBasis));
    }
  };

  return children({
    allPoints,
    extendible,
    translate,
    handleRemovePoint,
    handleDragEnd
  });
};

export default FlexStructure;
