import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import yaml from 'js-yaml';

import enTranslation from './locales/en/translation.yml';
import svTranslation from './locales/sv/translation.yml';

const lng = localStorage.getItem('topbar_language') ?? 'en';

const translationFiles = [{en: enTranslation}, {sv: svTranslation}];

const getYamlContent = async file => {
  try {
    const response = await fetch(file);
    const yamlContent = await response.text();
    const parsedTranslations = yaml.load(yamlContent);
    return parsedTranslations;
  } catch (error) {
    console.error('Error fetching translations:', error);
  }
};

const initializeI18next = async () => {
  const resourcesPromise = Promise.all(
    translationFiles.map(async translationObject => {
      const [language, translationFile] = Object.entries(translationObject)[0];
      const parsedFile = await getYamlContent(translationFile);
      return {[language]: parsedFile};
    })
  );

  return resourcesPromise.then(translationArray => {
    const resources = translationArray.reduce((acc, cur) => {
      const [language, translation] = Object.entries(cur)[0];
      acc[language] = {translation};
      return acc;
    }, {});

    if (resources) {
      return i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
          debug: true,
          resources,
          fallbackLng: 'en',
          defaultNS: 'translation',
          supportedLngs: ['en', 'sv'],
          ns: ['translation'],
          lng,
          interpolation: {
            escapeValue: false // Not needed for react as it escapes by default
          }
        });
    }
  });
};

export default initializeI18next;
