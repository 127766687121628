import {SelectionEvents} from '@fluentui/react-combobox';
import {
  makeStyles,
  tokens,
  shorthands,
  mergeClasses,
  Switch,
  Dropdown,
  Option,
  useId,
  Input,
  Label,
  Text,
  DropdownProps,
  InputProps
} from '@fluentui/react-components';

import {
  AoACapabilities,
  AoAScenario,
  OccupancyInAreaScenario,
  ThresholdType
} from '../../../../../types';
import AreaofInterest from '../AreaofInterest';
import ScenarioName from '../ScenarioName';
import VideoWrapper from '../VideoWrapper';

interface Props {
  readonly deviceImg: string;
  readonly scenario: OccupancyInAreaScenario;
  readonly deviceCapabilities: AoACapabilities;
  readonly updateScenarioConfiguration: (scenario: AoAScenario) => void;
}

const useStyles = makeStyles({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  configWrapper: {
    display: 'flex',
    justifyContent: 'center',
    ...shorthands.padding(tokens.spacingHorizontalXL, 0)
  },

  settingsWrapper: {
    ...shorthands.padding(0, tokens.spacingHorizontalXL),
    width: '45rem'
  },
  settingsSection: {
    ...shorthands.margin(0, 0, tokens.spacingVerticalXXXL)
  },
  settingsContent: {
    ...shorthands.padding(0, 0, 0, tokens.spacingHorizontalL),
    ...shorthands.margin(0, 0, tokens.spacingVerticalM)
  },
  controlsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& h4': {
      ...shorthands.margin(0, 0, tokens.spacingVerticalXS)
    },
    '& label': {
      ...shorthands.margin(0, tokens.spacingHorizontalM, 0, 0)
    }
  },
  countsInput: {
    width: '5rem'
  },
  timeInput: {
    maxWidth: '10rem'
  },
  thresholdDropdown: {
    minWidth: '10rem',
    ...shorthands.margin(0, tokens.spacingHorizontalM)
  },
  fullWidth: {
    width: '100%'
  }
});

const OccupancyInAreaScenarioTemplate = ({
  deviceImg,
  scenario,
  deviceCapabilities,
  updateScenarioConfiguration
}: Props) => {
  const styles = useStyles();
  const inputId = useId('timeInput');

  const {triggers: capTriggers, filters: capFilters, scenarios: capScenarios} = deviceCapabilities;

  const {
    thresholdConfiguration,
    eventInterval: {enabled}
  } = scenario;
  const {enabled: thresholdEnabled, thresholds, triggerDelay} = thresholdConfiguration;

  const toggleOccupancyThreshold = () => {
    updateScenarioConfiguration({
      ...scenario,
      thresholdConfiguration: {
        ...thresholdConfiguration,
        enabled: !thresholdEnabled
      }
    });
  };

  const onUpdateThreshold: DropdownProps['onOptionSelect'] = (_: SelectionEvents, data) => {
    updateScenarioConfiguration({
      ...scenario,
      thresholdConfiguration: {
        ...thresholdConfiguration,
        thresholds: [{...thresholds[0], type: data.selectedOptions[0] as ThresholdType}]
      }
    });
  };

  const onChangeThresholdValue: InputProps['onChange'] = (_, data) => {
    if (parseInt(data.value) <= 100) {
      updateScenarioConfiguration({
        ...scenario,
        thresholdConfiguration: {
          ...thresholdConfiguration,
          thresholds: [{...thresholds[0], level: parseInt(data.value)}]
        }
      });
    }
  };

  const onChangeTriggerDelay: InputProps['onChange'] = (_, data) => {
    if (parseInt(data.value) <= 7200) {
      updateScenarioConfiguration({
        ...scenario,
        thresholdConfiguration: {
          ...thresholdConfiguration,
          triggerDelay: parseInt(data.value)
        }
      });
    }
  };

  const toggleEventInterval = () => {
    updateScenarioConfiguration({
      ...scenario,
      eventInterval: {
        enabled: !enabled
      }
    });
  };

  return (
    <div className={mergeClasses(styles.wrapper, styles.fullWidth)}>
      <ScenarioName
        minLengthName={capScenarios.minLengthName || undefined}
        maxLengthName={capScenarios.maxLengthName || undefined}
        currentScenarioName={scenario.name}
        onUpdate={name => updateScenarioConfiguration({...scenario, name})}
      />
      <div className={mergeClasses(styles.configWrapper, styles.fullWidth)}>
        <VideoWrapper
          deviceImg={deviceImg}
          scenario={scenario}
          capTriggerVertices={capTriggers.includeArea}
          onUpdate={scenario => 'id' in scenario && updateScenarioConfiguration(scenario)}
        />
        <div className={styles.settingsWrapper}>
          <div className={styles.settingsSection}>
            <AreaofInterest
              scenario={scenario}
              capFiltersExcludeAreaDefault={capFilters.excludeArea?.defaultInstance}
              capTriggersDefaultInstance={capTriggers.includeArea.defaultInstance}
              onUpdate={scenario => 'id' in scenario && updateScenarioConfiguration(scenario)}
            />
          </div>
          <div className={styles.settingsSection}>
            <h3>Occupancy settings</h3>
            <Switch
              checked={thresholdEnabled}
              label={
                <div>
                  <h4>Occupancy threshold</h4>
                </div>
              }
              labelPosition="before"
              className={styles.controlsContainer}
              onChange={toggleOccupancyThreshold}
            />
            <div className={styles.settingsContent}>
              <p>
                Set up an occupancy threshold to trigger actions based on occupancy levels in the
                area of interest.
              </p>
              <div className={styles.controlsContainer}>
                <h4>Number of objects</h4>
                <div className={styles.controlsContainer}>
                  <Dropdown
                    className={styles.thresholdDropdown}
                    defaultValue={'More than'}
                    defaultSelectedOptions={['moreThan']}
                    disabled={!thresholdEnabled}
                    onOptionSelect={onUpdateThreshold}
                  >
                    {['moreThan', 'lessThan'].map(option => (
                      <Option key={option} value={option}>
                        {option === 'moreThan' ? 'More than' : 'Less than'}
                      </Option>
                    ))}
                  </Dropdown>
                  <Input
                    disabled={!thresholdEnabled}
                    className={styles.countsInput}
                    defaultValue="0"
                    value={thresholds[0].level.toString()}
                    onChange={onChangeThresholdValue}
                  />
                </div>
              </div>
            </div>
            <div className={styles.settingsContent}>
              <h4>Trigger action after set time</h4>
              <div className={styles.controlsContainer}>
                <Label htmlFor={inputId} disabled={!thresholdEnabled}>
                  Set the time during which the occupancy threshold must hold valid before the
                  scenario triggers an action.
                </Label>
                <Input
                  disabled={!thresholdEnabled}
                  className={styles.timeInput}
                  id={inputId}
                  defaultValue="0"
                  value={triggerDelay.toString()}
                  contentAfter={<Text size={400}>seconds</Text>}
                  onChange={onChangeTriggerDelay}
                />
              </div>
            </div>

            <Switch
              checked={enabled}
              label={
                <div>
                  <h4>Event interval</h4>
                </div>
              }
              labelPosition="before"
              className={styles.controlsContainer}
              onChange={toggleEventInterval}
            />
            <div className={styles.settingsContent}>
              Send events with occupancy data at one-minute intervals. Includes min, max and average
              occupancy during the interval.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OccupancyInAreaScenarioTemplate;
