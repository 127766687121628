import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export const useAppStyles = makeStyles({
  app: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: tokens.colorNeutralBackground4,
    overflow: 'hidden'
  },
  body: {
    overflow: 'hidden',
    backgroundColor: tokens.colorNeutralBackground4,
    display: 'flex',
    flexGrow: 1
  },
  content: {
    display: 'flex',
    position: 'relative',
    ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralShadowKeyLighter),
    borderRadius: `${tokens.borderRadiusXLarge} 0 0 0`,
    overflow: 'hidden',
    backgroundColor: tokens.colorNeutralBackground3,
    boxShadow: tokens.shadow4,
    width: '100%',
    animationName: {
      from: {opacity: 0},
      to: {opacity: 1}
    },
    animationDuration: '1s'
  }
});
