import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import {useCallback, useEffect, useState} from 'react';

import {fetchAoaCapabilitites, fetchAoaConfig} from '../../../../../fetcher';
import {
  addDeviceAoADetails,
  createOrUpdateScenarioTemplate
} from '../../../../../reducers/deviceDetailsSlice';
import {useAppDispatch, useAppSelector} from '../../../../../store/hooks';
import {ScenarioType, AoAScenario, AoACapabilities} from '../../../../../types';
import {useToken} from '../../../../../hooks/useToken';
import Loader from '../../../../Loader';
import CrosslineCountingScenarioTemplate from '../CrosslineCountingScenarioTemplate';
import LineCrossingScenarioTemplate from '../LineCrossingScenarioTemplate';
import OccupancyInAreaScenarioTemplate from '../OccupancyInAreaScenarioTemplate';
import {
  aoaCrosslineCountingScenarioTemplate,
  aoaLinecrossingScenarioTemplate,
  occupancyInAreaTemplate,
  motionInAreaTemplate
} from '../scenarioTemplates';

interface Props {
  readonly deviceSerial: string;
}

const DEFAULT_SCENARIO_TEMPLATE_AOA = {
  [ScenarioType.LINE_CROSSING]: aoaLinecrossingScenarioTemplate,
  [ScenarioType.CROSSLINE_COUNTING]: aoaCrosslineCountingScenarioTemplate,
  [ScenarioType.OCCUPANCY_IN_AREA]: occupancyInAreaTemplate,
  [ScenarioType.MOTION_IN_AREA]: motionInAreaTemplate
};

const DeviceScenarioConfiguration = ({deviceSerial}: Props) => {
  const token = useToken();
  const {organization} = usePopulatedTopbarValues();
  const dispatch = useAppDispatch();
  const {devicesAoADetails, commonConfiguration, devicesGeneralDetails} = useAppSelector(
    state => state.deviceDetailsSlice
  );

  const generalDeviceInfo = devicesGeneralDetails.find(dev => dev.serial === deviceSerial);
  const currentDeviceDetails = devicesAoADetails.find(currDev => currDev.serial === deviceSerial);

  const [img, setImg] = useState<string>();

  const createOrUpdateScenario = useCallback(
    (serial: string, scenario: AoAScenario) =>
      dispatch(
        createOrUpdateScenarioTemplate({
          serial,
          scenario: scenario
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    if (!commonConfiguration.template) return;

    const currentDevice = devicesGeneralDetails.find(currDev => currDev.serial === deviceSerial);

    if (!currentDevice?.newScenarioTemplate) {
      if (currentDevice?.aiSupported) {
        const currentDeviceAoAInformation = devicesAoADetails.find(
          currDev => currDev.serial === deviceSerial
        );
        const defaultScenarioTemplate = DEFAULT_SCENARIO_TEMPLATE_AOA[commonConfiguration.template];
        if (currentDeviceAoAInformation) {
          const newAoaScenario = {
            ...defaultScenarioTemplate,
            id: (currentDeviceAoAInformation.configuration.scenarios.at(-1)?.id || 1) + 1,
            name: `Scenario ${currentDeviceAoAInformation.configuration.scenarios.length + 1}`
          };
          createOrUpdateScenario(deviceSerial, newAoaScenario);
        }
      }
    }
  }, [
    deviceSerial,
    commonConfiguration.template,
    devicesAoADetails,
    devicesGeneralDetails,
    createOrUpdateScenario
  ]);

  useEffect(() => {
    if (!organization || !token) {
      return;
    }

    if (generalDeviceInfo) {
      setImg(generalDeviceInfo.img);
    }

    const currentDeviceAoADetails = devicesAoADetails.find(
      deviceInfo => deviceInfo.serial === deviceSerial
    );

    if (!currentDeviceAoADetails && generalDeviceInfo?.aiSupported) {
      const getDeviceAoAConfig = fetchAoaConfig(token, organization.arn, deviceSerial);
      const getDeviceAoACapabilities = fetchAoaCapabilitites(token, organization.arn, deviceSerial);

      Promise.all([getDeviceAoAConfig, getDeviceAoACapabilities])
        .then(([config, capabilities]) =>
          dispatch(
            addDeviceAoADetails({
              serial: deviceSerial,
              configuration: config.data,
              capabilities: capabilities.data
            })
          )
        )
        .catch(console.log);
    }
  }, [
    token,
    deviceSerial,
    organization,
    generalDeviceInfo,
    dispatch,
    devicesGeneralDetails,
    devicesAoADetails
  ]);

  if (!img || !generalDeviceInfo?.newScenarioTemplate || !currentDeviceDetails) {
    return <Loader />;
  }

  return (
    <AoaParent
      scenario={generalDeviceInfo.newScenarioTemplate}
      deviceImg={img}
      updateScenarioConfiguration={scenario => createOrUpdateScenario(deviceSerial, scenario)}
      deviceCapabilities={currentDeviceDetails.capabilities}
    />
  );
};

const AoaParent: React.FC<{
  deviceImg: string;
  scenario: AoAScenario;
  updateScenarioConfiguration: (scenario: AoAScenario) => void;
  deviceCapabilities: AoACapabilities;
}> = ({...childProps}) => {
  if (childProps.scenario.type === ScenarioType.LINE_CROSSING) {
    return (
      <LineCrossingScenarioTemplate
        {...childProps}
        scenario={childProps.scenario}
        deviceCapabilities={childProps.deviceCapabilities}
      />
    );
  } else if (childProps.scenario.type === ScenarioType.CROSSLINE_COUNTING) {
    return (
      <CrosslineCountingScenarioTemplate
        {...childProps}
        scenario={childProps.scenario}
        deviceCapabilities={childProps.deviceCapabilities}
      />
    );
  } else if (childProps.scenario.type === ScenarioType.OCCUPANCY_IN_AREA) {
    return (
      <OccupancyInAreaScenarioTemplate
        {...childProps}
        scenario={childProps.scenario}
        deviceCapabilities={childProps.deviceCapabilities}
      />
    );
  }
  return <Loader />;
};

export default DeviceScenarioConfiguration;
