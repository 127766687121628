import {useCallback, useEffect, useState} from 'react';
import {BrowserRouter} from 'react-router-dom';
import Main from './Main';
import initializeI18next from './i18n';
import Loader from './views/Loader';
import {useAppStyles} from './style/appStyles';
import {DidOpenTelemetryProvider} from './OpenTelemetryProvider';
import {TopbarProvider} from './TopbarProvider';
import ApiProvider from './api/provider';

export const App = () => {
  const appStyles = useAppStyles();
  const [isReady, setIsReady] = useState(false);

  const loadTranslationResources = useCallback(async () => {
    const i18nRes = await initializeI18next();
    if (i18nRes) {
      setIsReady(true);
    }
  }, []);

  useEffect(() => {
    loadTranslationResources();
  }, [loadTranslationResources]);

  if (!isReady) {
    return <Loader />;
  }

  return (
    <div className={appStyles.app}>
      <DidOpenTelemetryProvider>
        <BrowserRouter>
          <TopbarProvider>
            <ApiProvider>
              <Main />
            </ApiProvider>
          </TopbarProvider>
        </BrowserRouter>
      </DidOpenTelemetryProvider>
    </div>
  );
};
