import {PropsWithChildren, createContext, useState} from 'react';
import {FluentProvider} from '@fluentui/react-components';
import {axisDarkTheme, axisLightTheme} from '@axiscommunications/fluent-theme';

import {useGlobalStaticStyle} from './styles';

export type ThemeTypes = 'light' | 'dark';
export const ThemeContext = createContext<{
  theme: ThemeTypes;
  setTheme: (theme: ThemeTypes) => void;
}>({theme: 'light', setTheme: () => undefined});

export const FluentProviderWrapper = ({children}: PropsWithChildren) => {
  useGlobalStaticStyle();

  const [theme, setTheme] = useState<ThemeTypes>('light');

  return (
    <ThemeContext.Provider value={{theme, setTheme}}>
      <FluentProvider theme={theme === 'light' ? axisLightTheme : axisDarkTheme}>
        {children}
      </FluentProvider>
    </ThemeContext.Provider>
  );
};
