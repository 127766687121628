import React from 'react';
import {doubled, CoordArray} from 'media-overlay-library';
import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

import translateFlexStructure from '../../../helpers/translateFlexStructure';
import {Point, GhostPoint} from '../Points';
import useDraggable from '../../../hooks/useDraggable';
import FlexStructure, {FlexStructureRenderProps} from '../FlexStructure';

/**
 * Tripwire - Flexiline with indication of alarm direction
 *
 * Support for removing points (right mouse button, ctrl-click or long-press on touch.)
 */

let flexilineId = 0;
const MAX_NUMBER_OF_POINTS = 10;
const MIN_NUMBER_OF_POINTS = 2;
const DISTANCE_FROM_LINE = 18;

const SMALL_ARROW_COUNT_THRESHOLD = 1 / 2;
const LARGE_ARROW_COUNT_THRESHOLD = 1 + 1 / 2;

const SMALL_ARROW_COUNT_FACTOR = 1;
const LARGE_ARROW_COUNT_FACTOR = 1 / 4;

const SMALL_ARROW_SCALE = 1 / 2;
const LARGE_ARROW_SCALE = 2;

interface ArrowProps {
  readonly x1: number;
  readonly y1: number;
  readonly x2: number;
  readonly y2: number;
  readonly ltr: boolean;
}

interface TripwireProps {
  readonly points: CoordArray;
  readonly onUpdate: (vertices: CoordArray) => void;
  readonly maxNumberOfPoints?: number;
  readonly minNumberOfPoints?: number;
  readonly className?: string;
  readonly ltr?: boolean;
}

interface DraggableFlexilineProps {
  readonly points: CoordArray;
  readonly extendible: boolean;
  readonly ltr: boolean;
  readonly translate: ReturnType<typeof translateFlexStructure>;
  readonly onDragEnd: (t: CoordArray, id: string | null) => void;
  readonly onRemovePoint: (id: number) => void;
  readonly className: string;
}

const useStyles = makeStyles({
  tripWireBorder: {
    cursor: 'move',
    fill: 'transparent',
    ...shorthands.margin(tokens.spacingVerticalXS),
    stroke: '#ffcc33 !important',
    strokeWidth: '0.125rem'
  },
  tripwireArrow: {fill: 'none', stroke: 'red', strokeLinecap: 'square', strokeWidth: ' 0.1875rem'},
  transTripwirePolyline: {
    fill: 'none',
    stroke: '#ffcc33',
    strokeWidth: '0.125rem',
    cursor: 'move'
  },
  tripwirePolyline: {
    fill: 'none',
    pointerEvents: 'none',
    stroke: '#ffcc33',
    strokeOpacity: '1',
    strokeWidth: '0.125rem'
  },
  pointStyles: {
    '& circle': {
      cursor: 'pointer',
      fill: 'transparent',
      stroke: '#ffcc33',
      strokeWidth: '0.125rem'
    }
  },
  ghostPointStyles: {
    '& circle': {
      cursor: 'pointer',
      fill: 'transparent',
      stroke: '#ffcc33',
      strokeWidth: '0.125rem'
    }
  }
});

const Arrow = ({x1, y1, x2, y2, ltr}: ArrowProps) => {
  const styles = useStyles();

  // distance between the points
  let dist = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

  // number of arrows for line segment
  const fArrow = dist / (DISTANCE_FROM_LINE * 2);
  let nArrow = Math.max(Math.round(fArrow), 1);

  // adjust arrow scale and count according to number of arrows fitting on line
  if (fArrow < SMALL_ARROW_COUNT_THRESHOLD) {
    dist /= SMALL_ARROW_SCALE;
    nArrow = Math.round(nArrow * SMALL_ARROW_COUNT_FACTOR);
  } else if (fArrow > LARGE_ARROW_COUNT_THRESHOLD) {
    dist /= LARGE_ARROW_SCALE;
    nArrow = Math.round(nArrow * LARGE_ARROW_COUNT_FACTOR);
  }

  const arrows: React.ReactNode[] = [];

  for (let i = 0; i < nArrow; i++) {
    // alpha: (0.0 - 1.0) where on line to put the arrow
    const alpha = i / nArrow + 1 / nArrow / 2;

    // coordinates for the midpoint between x1, y1 and x2, y2.
    const midX = x1 * alpha + x2 * (1 - alpha);
    const midY = y1 * alpha + y2 * (1 - alpha);

    // coordinates for the tip of the arrow
    const cx = midX + (DISTANCE_FROM_LINE * (y2 - y1) * (ltr ? -1 : 1)) / dist;
    const cy = midY - (DISTANCE_FROM_LINE * (x2 - x1) * (ltr ? -1 : 1)) / dist;

    // coordinates for the midpoint between midX, midY and cx, cy.
    const midCx = (midX + cx) / 2;
    const midCy = (midY + cy) / 2;

    // coordinates for bottom-left corner of the arrow
    const ax = midCx + (10 * (midY - cy)) / 20;
    const ay = midCy - (10 * (midX - cx)) / 20;

    // coordinates for bottom-right corner of the arrow
    const bx = midCx + (10 * (cy - midY)) / 20;
    const by = midCy - (10 * (cx - midX)) / 20;

    arrows.push(
      <g key={i}>
        <polyline className={styles.tripwireArrow} points={`${ax},${ay} ${cx},${cy} ${bx},${by}`} />
      </g>
    );
  }

  return <React.Fragment>{arrows}</React.Fragment>;
};

const DraggableFlexiline = ({
  points,
  extendible,
  ltr,
  translate,
  onDragEnd,
  onRemovePoint,
  className
}: DraggableFlexilineProps) => {
  const styles = useStyles();
  const [id, t, onDragStart] = useDraggable((_id, _t) => {
    const _allPoints = translate(points, _id, _t);
    onDragEnd(_allPoints, _id);
  });

  const allPoints = translate(points, id, t).slice(0, -1);

  const handlePointPointerDown = (e: React.PointerEvent, _id: number) => {
    if (e.ctrlKey) {
      onRemovePoint(_id);
      e.stopPropagation();
      return false;
    }

    onDragStart(e, _id);
  };

  const lines = doubled(allPoints).slice(0, -1);

  return (
    <g className={className}>
      {lines.map(([[x1, y1], [x2, y2]], _id) => {
        return (
          <React.Fragment key={_id}>
            <line className={styles.tripWireBorder} key={_id} {...{x1, y1, x2, y2}} />
            <Arrow {...{x1, y1, x2, y2, ltr}} />
          </React.Fragment>
        );
      })}
      <polyline
        className={styles.transTripwirePolyline}
        onPointerDown={(e: React.PointerEvent<Element>) => onDragStart(e, 'FLEX_STRUCTURE')}
        points={allPoints.map(([x, y]) => `${x},${y}`).join(' ')}
      />
      <polyline
        className={styles.tripwirePolyline}
        points={allPoints.map(([x, y]) => `${x},${y}`).join(' ')}
      />
      {allPoints.map(([cx, cy], _id) => {
        if (_id % 2 === 0) {
          // these are the points
          return (
            <Point
              key={_id}
              id={_id.toString()}
              cx={cx}
              cy={cy}
              onPointerDown={(e: React.PointerEvent<Element>) => handlePointPointerDown(e, _id)}
              onRightClick={() => onRemovePoint(_id)}
              className={styles.pointStyles}
            />
          );
        }
        // these are the ghost points
        return (
          extendible && (
            <GhostPoint
              key={_id}
              cx={cx}
              cy={cy}
              onPointerDown={e => onDragStart(e, _id)}
              className={styles.ghostPointStyles}
            />
          )
        );
      })}
    </g>
  );
};

const Tripwire = ({
  points,
  onUpdate,
  maxNumberOfPoints = MAX_NUMBER_OF_POINTS,
  minNumberOfPoints = MIN_NUMBER_OF_POINTS,
  className = 'tripwire',
  ltr = false
}: TripwireProps) => (
  <FlexStructure
    points={points}
    onUpdate={onUpdate}
    maxNumberOfPoints={maxNumberOfPoints}
    minNumberOfPoints={minNumberOfPoints}
  >
    {({
      allPoints,
      extendible,
      translate,
      handleRemovePoint,
      handleDragEnd
    }: FlexStructureRenderProps) => (
      <DraggableFlexiline
        key={flexilineId++}
        points={allPoints}
        extendible={extendible}
        ltr={ltr}
        translate={translate}
        onDragEnd={handleDragEnd}
        onRemovePoint={handleRemovePoint}
        className={className}
      />
    )}
  </FlexStructure>
);

export default Tripwire;
