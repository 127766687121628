import React, {useEffect} from 'react';
import {doubled, CoordArray} from 'media-overlay-library';
import translateFlexStructure from '../../../helpers/translateFlexStructure';
import FlexStructure from '../FlexStructure';
import useDraggable from '../../../hooks/useDraggable';
import {makeStyles, mergeClasses, shorthands, tokens} from '@fluentui/react-components';
import {GhostPoint, Point} from '../Points';

let flexigonId = 0;
const MAX_NUMBER_OF_POINTS = 10;
const MIN_NUMBER_OF_POINTS = 3;

interface IFlexigonProps {
  readonly points: CoordArray;
  readonly className?: string;
  readonly minNumberOfPoints?: number;
  readonly maxNumberOfPoints?: number;
  readonly onUpdate: (vertices: CoordArray) => void;
  readonly onDrag: (coords: CoordArray) => void;
}

interface IDraggableFlexigonProps {
  readonly points: CoordArray;
  readonly className: string;
  readonly translate: ReturnType<typeof translateFlexStructure>;
  readonly extendible: boolean;
  readonly onDragEnd: (t: CoordArray, id: string | null) => void;
  readonly onRemovePoint: (id: number) => void;
  readonly onDrag: (coords: CoordArray) => void;
}

const useStyles = makeStyles({
  flexigonPoint: {
    '&.includeArea, &[class^="excludeArea"]': {
      cursor: 'move',
      fill: 'transparent',
      ...shorthands.margin(tokens.spacingVerticalXS),
      stroke: '#ffcc33',
      strokeWidth: '0.125rem'
    },
    '& .flexigon__point, & .flexigon__ghost-point': {
      cursor: 'pointer'
    }
  }
});

const Flexigon = ({
  points,
  className = 'flexigon',
  minNumberOfPoints = MIN_NUMBER_OF_POINTS,
  maxNumberOfPoints = MAX_NUMBER_OF_POINTS,
  onUpdate,
  onDrag
}: IFlexigonProps) => (
  <FlexStructure
    points={points}
    onUpdate={onUpdate}
    maxNumberOfPoints={maxNumberOfPoints}
    minNumberOfPoints={minNumberOfPoints}
  >
    {({allPoints, extendible, translate, handleRemovePoint, handleDragEnd}) => (
      <DraggableFlexigon
        key={flexigonId++}
        points={allPoints}
        extendible={extendible}
        translate={translate}
        onDragEnd={handleDragEnd}
        onRemovePoint={handleRemovePoint}
        className={className}
        onDrag={onDrag}
      />
    )}
  </FlexStructure>
);

const DraggableFlexigon = ({
  points,
  className,
  translate,
  extendible,
  onDragEnd,
  onRemovePoint,
  onDrag
}: IDraggableFlexigonProps) => {
  const [id, t, onDragStart] = useDraggable((_id, _t) => {
    const _allPoints = translate(points, _id, _t);
    onDragEnd(_allPoints, _id);
  });
  const styles = useStyles();
  const allPoints = translate(points, id, t);

  useEffect(() => {
    onDrag(allPoints);
  }, [onDrag, allPoints]);

  useEffect(
    () => () => {
      onDrag([]);
    },
    [onDrag]
  );

  const handlePointPointerDown = (e: React.PointerEvent, _id: number) => {
    if (e.ctrlKey) {
      onRemovePoint(_id);
      e.stopPropagation();
      return false;
    }

    onDragStart(e, _id);
  };

  return (
    <g
      className={mergeClasses(styles.flexigonPoint, className)}
      onPointerDown={e => onDragStart(e, 'FLEX_STRUCTURE')}
    >
      {doubled(allPoints).map(([[x1, y1], [x2, y2]], _id) => (
        <line key={_id} {...{x1, y1, x2, y2}} className="flexigon__border" />
      ))}
      <polygon
        points={allPoints.map(([x, y]) => `${x},${y}`).join(' ')}
        className="flexigon__filling"
      />
      {allPoints.map(([cx, cy], _id) => {
        if (_id % 2 === 0) {
          // these are the points
          return (
            <Point
              key={_id}
              id={_id.toString()}
              cx={cx}
              cy={cy}
              onPointerDown={e => handlePointPointerDown(e, _id)}
              onRightClick={() => onRemovePoint(_id)}
              className="flexigon__point"
            />
          );
        }
        // these are the ghost points
        return (
          extendible && (
            <GhostPoint
              key={_id}
              cx={cx}
              cy={cy}
              onPointerDown={e => onDragStart(e, _id)}
              className="flexigon__ghost-point"
            />
          )
        );
      })}
    </g>
  );
};

export default Flexigon;
