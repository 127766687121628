import {createSlice} from '@reduxjs/toolkit';
import {
  CapTriggerIncludeAreaIndividualTimeInAreaTimes,
  AoACapabilities,
  CapFilterExcludeArea,
  CapFilterShortLived,
  CapFilterSizePercentage,
  CapFilterSizePerspective,
  CapFilterSpeed,
  CapFilterSwaying,
  CapTriggerCountingLine,
  CapTriggerLineCrossing,
  DeviceAoAInformation,
  TriggerType,
  ScenarioType,
  ObjectClassification,
  Nullable,
  AoAScenario,
  FilterTypes,
  TriggerTypes
} from '../types';

export interface DeviceDetailsState {
  readonly devicesAoADetails: DeviceAoAInformation[];
  readonly commonConfiguration: CommonConfiguration;
  readonly devicesGeneralDetails: DeviceGeneralDetails[];
  readonly eventRule: EventRule;
}

export interface CommonConfiguration {
  readonly template: Nullable<ScenarioType>;
  readonly objectClassifications: ObjectClassification[];
}

export interface RuleCondition {
  serial: string;
  name: string;
  selected: boolean;
}

export interface EventRule {
  actionId: number;
  ruleName: string;
  waitTime: string;
  videoChannel: string;
  overlayText: string;
  ruleConditions: RuleCondition[];
  isValid: boolean;
}

export type DeviceGeneralDetails = {
  readonly aiSupported: boolean;
  readonly serial: string;
  readonly img: string;
  readonly newScenarioTemplate: Nullable<AoAScenario>;
};

const deviceDetailsInitialState: DeviceDetailsState = {
  devicesAoADetails: [],
  commonConfiguration: {template: null, objectClassifications: []},
  devicesGeneralDetails: [],
  eventRule: {
    actionId: 1,
    ruleName: '',
    waitTime: '0',
    videoChannel: '',
    overlayText: '',
    ruleConditions: [],
    isValid: true
  }
};

export const deviceDetailsSlice = createSlice({
  name: 'deviceDetails',
  initialState: deviceDetailsInitialState,
  reducers: {
    setCommonConfiguration: (state, action) => ({
      ...state,
      commonConfiguration: {...state.commonConfiguration, ...action.payload}
    }),
    addDeviceDetails: (state, action) => ({...state, devicesGeneralDetails: action.payload}),
    addEventRule: (state, action) => ({
      ...state,
      eventRule: {...state.eventRule, ...action.payload}
    }),
    resetDevicesDetails: () => deviceDetailsInitialState,
    resetDeviceCommonDetails: state => ({
      ...state,
      commonConfiguration: {template: null, objectClassifications: []},
      devicesAoADetails: []
    }),
    addDeviceAoADetails: (state, action) => {
      const isExistingDevice = state.devicesAoADetails.some(
        config => config.serial === action.payload.serial
      );
      if (!isExistingDevice) {
        const includeArea = action.payload.capabilities.triggers.find(
          ({type}: TriggerType) => type === TriggerTypes.INCLUDE_AREA
        );

        if (includeArea.conditions) {
          const conditionTypes = includeArea.conditions.validConditionTypes.reduce(
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            (acc: any, curr: any) => {
              const {type, classifications, ...rest} = curr;
              acc[type] = rest;

              if (classifications) {
                //eslint-disable-next-line @typescript-eslint/no-explicit-any
                const formattedClassifications = classifications.reduce((acc2: any, curr2: any) => {
                  const {type: type2, ...rest2} = curr2;
                  acc2[type2] = rest2;

                  return acc2;
                }, {});
                acc[type] = {...formattedClassifications};
              }

              return acc;
            },
            {}
          ) as CapTriggerIncludeAreaIndividualTimeInAreaTimes;

          includeArea.conditions = conditionTypes;
        }

        const newCapabilities: AoACapabilities = {
          ...action.payload.capabilities,
          filters: {
            sizePerspective: action.payload.capabilities.filters.find(
              ({type}: CapFilterSizePerspective) => type === FilterTypes.SIZE_PERSPECTIVE_FILTER
            ),
            sizePercentage: action.payload.capabilities.filters.find(
              ({type}: CapFilterSizePercentage) => type === FilterTypes.SIZE_PERCENTAGE_FILTER
            ),
            shortLived: action.payload.capabilities.filters.find(
              ({type}: CapFilterShortLived) => type === FilterTypes.SHORT_LIVED_FILTER
            ),
            swayingObject: action.payload.capabilities.filters.find(
              ({type}: CapFilterSwaying) => type === FilterTypes.SWAYING_OBJECT_FILTER
            ),
            excludeArea: action.payload.capabilities.filters.find(
              ({type}: CapFilterExcludeArea) => type === TriggerTypes.EXCLUDE_AREA
            ),
            speed: action.payload.capabilities.filters.find(
              ({type}: CapFilterSpeed) => type === FilterTypes.SPEED_FILTER
            )
          },
          triggers: {
            includeArea,
            lineCrossing: action.payload.capabilities.triggers.find(
              ({type}: CapTriggerLineCrossing) => type === TriggerTypes.LINE_CROSSING
            ),
            countingLine: action.payload.capabilities.triggers.find(
              ({type}: CapTriggerCountingLine) => type === TriggerTypes.COUNTING_LINE
            )
          }
        };
        state.devicesAoADetails = [
          ...state.devicesAoADetails,
          {...action.payload, capabilities: newCapabilities}
        ];
      }
    },
    createOrUpdateScenarioTemplate: (state, action) => {
      const currentDeviceDetails = state.devicesGeneralDetails.find(
        device => device.serial === action.payload.serial
      );

      if (currentDeviceDetails) {
        currentDeviceDetails.newScenarioTemplate = action.payload.scenario;
      }
    },
    addAoAScenario: (state, action) => {
      const {objectClassifications} = state.commonConfiguration;
      const currentDeviceDetails = state.devicesGeneralDetails.find(
        device => device.serial === action.payload.serial
      );

      if (currentDeviceDetails) {
        currentDeviceDetails.newScenarioTemplate = null;
        const deviceAoADetails = state.devicesAoADetails;

        if (deviceAoADetails) {
          const currentDeviceAoADetails = deviceAoADetails.find(
            device => device.serial === action.payload.serial
          );
          if (currentDeviceAoADetails) {
            currentDeviceAoADetails.configuration.scenarios = [
              ...currentDeviceAoADetails.configuration.scenarios,
              {...action.payload.scenario, objectClassifications: objectClassifications}
            ];
          }
        }
      }
    }
  }
});

export const {
  addEventRule,
  addDeviceDetails,
  resetDevicesDetails,
  resetDeviceCommonDetails,
  addDeviceAoADetails,
  createOrUpdateScenarioTemplate,
  addAoAScenario,
  setCommonConfiguration
} = deviceDetailsSlice.actions;
export default deviceDetailsSlice.reducer;
