import {CoordArray} from 'media-overlay-library';
import arraysAreEqual from '../../../../../helpers/arraysAreEqual';
import {
  AoAScenario,
  CrosslineCountingAoAScenario,
  LineCrossingAoAScenario,
  Nullable,
  ScenarioType,
  TriggerMotion,
  MotionInAreaScenario,
  OccupancyInAreaScenario,
  LineDirection,
  TriggerTypes
} from '../../../../../types';
import ExcludeOverlay from '../../../../Svg/ExcludeOverlay';
import Tripwire from '../../../../Svg/Tripwire';
import FoundationLayer from '../FoundationLayer';

interface DrawerOverlayProps {
  readonly width: number;
  readonly height: number;
  readonly scenario: AoAScenario;
  readonly capTriggersMinMaxVertices: {
    minNbrVertices: Nullable<number>;
    maxNbrVertices: Nullable<number>;
  };
  readonly onUpdate: (scenario: AoAScenario) => void;
}

const isLineCrossingScenario = (
  scenario: LineCrossingAoAScenario | CrosslineCountingAoAScenario
): scenario is LineCrossingAoAScenario => scenario.type === ScenarioType.LINE_CROSSING;

const DrawerOverlay = ({
  width,
  height,
  scenario,
  capTriggersMinMaxVertices,
  onUpdate
}: DrawerOverlayProps) => {
  // Motion and Occupancy
  if (
    (!('type' in scenario) ||
      scenario.type === ScenarioType.MOTION_IN_AREA ||
      scenario.type === ScenarioType.OCCUPANCY_IN_AREA) &&
    scenario.triggers[0].type === TriggerTypes.INCLUDE_AREA
  ) {
    // Using flatMap instead of filter here to allow TS to infer the right type
    const excludeAreas = scenario.filters.flatMap(item =>
      item.type === TriggerTypes.EXCLUDE_AREA ? [item] : []
    );
    const {minNbrVertices, maxNbrVertices} = capTriggersMinMaxVertices;

    const onUpdateExcludeAreaHandler = (
      oldVertices: ReadonlyArray<readonly [number, number]>,
      newVertices: CoordArray,
      scenario: OccupancyInAreaScenario | MotionInAreaScenario
    ) => {
      const newFilters = [...scenario.filters];

      // Finding the index specifically inorder to update the first excludeArea individually as they gets stacked on the same coords initially.
      const idx = newFilters.findIndex(
        filter =>
          filter.type === TriggerTypes.EXCLUDE_AREA && arraysAreEqual(filter.vertices, oldVertices)
      );

      if (idx >= 0) {
        newFilters[idx] = {type: TriggerTypes.EXCLUDE_AREA, vertices: newVertices};
      }

      onUpdate({
        ...scenario,
        filters: newFilters
      });
    };

    const onUpdateIncludeArea = (vertices: CoordArray) => {
      const trigger: TriggerMotion = scenario.triggers[0];

      onUpdate({
        ...scenario,
        triggers: [
          {
            ...trigger,
            vertices
          }
        ]
      });
    };

    return (
      <FoundationLayer>
        <ExcludeOverlay
          width={width}
          height={height}
          trigger={scenario.triggers[0]}
          excludeAreas={excludeAreas}
          {...(minNbrVertices && {minNbrVertices})}
          {...(maxNbrVertices && {maxNbrVertices})}
          onUpdateIncludeArea={onUpdateIncludeArea}
          onUpdateExcludeArea={(vertices, data) =>
            onUpdateExcludeAreaHandler(vertices, data, scenario)
          }
        />
      </FoundationLayer>
    );
  }

  // Line crossing and Crossline counting
  if (
    'type' in scenario &&
    ((scenario.type === ScenarioType.LINE_CROSSING &&
      scenario.triggers[0].type === TriggerTypes.LINE_CROSSING) ||
      (scenario.type === ScenarioType.CROSSLINE_COUNTING &&
        scenario.triggers[0].type === TriggerTypes.COUNTING_LINE))
  ) {
    const {minNbrVertices, maxNbrVertices} = capTriggersMinMaxVertices;
    const ltr =
      (scenario.triggers[0].type === TriggerTypes.LINE_CROSSING
        ? scenario.triggers[0].alarmDirection
        : scenario.triggers[0].type === TriggerTypes.COUNTING_LINE &&
          scenario.triggers[0].countingDirection) === LineDirection.LTR;

    const onUpdateLineCrossing = (vertices: CoordArray) => {
      if (isLineCrossingScenario(scenario)) {
        onUpdate({
          ...scenario,
          triggers: [
            {
              ...scenario.triggers[0],
              vertices
            }
          ]
        });
      } else {
        onUpdate({
          ...scenario,
          triggers: [
            {
              ...scenario.triggers[0],
              vertices
            }
          ]
        });
      }
    };

    return (
      <FoundationLayer>
        <Tripwire
          points={scenario.triggers[0].vertices}
          {...(minNbrVertices && {
            minNumberOfPoints: minNbrVertices
          })}
          {...(maxNbrVertices && {
            maxNumberOfPoints: maxNbrVertices
          })}
          ltr={ltr}
          onUpdate={onUpdateLineCrossing}
        />
      </FoundationLayer>
    );
  }
  return null;
};

export default DrawerOverlay;
