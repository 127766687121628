import {makeStyles, Image, mergeClasses} from '@fluentui/react-components';

import {AoAScenario, Nullable} from '../../../../../types';
import DrawerOverlay from '../DrawerOverlay';
import {useEffect, useRef, useState} from 'react';

const useStyles = makeStyles({
  imageWrapper: {
    position: 'relative'
  },
  fullWidth: {
    width: '100%'
  },
  videoWrapper: {
    width: '55rem'
  }
});

interface VideoWrapperProps {
  readonly deviceImg: string;

  readonly scenario: AoAScenario;
  readonly capTriggerVertices: {
    minNbrVertices: Nullable<number>;
    maxNbrVertices: Nullable<number>;
  };
  readonly onUpdate: (scenario: AoAScenario) => void;
}

const VideoWrapper = ({deviceImg, scenario, capTriggerVertices, onUpdate}: VideoWrapperProps) => {
  const styles = useStyles();
  const videoWrapperRef = useRef<HTMLDivElement>(null);
  const [videoDimensions, setVideoDimensions] = useState({width: 0, height: 0});

  useEffect(() => {
    if (videoWrapperRef.current) {
      const observer = new ResizeObserver(entries => {
        setVideoDimensions({
          width: entries[0].contentRect.width,
          height: entries[0].contentRect.height
        });
      });
      observer.observe(videoWrapperRef.current);
      return () => observer.disconnect();
    }
  }, []);

  return (
    <div className={styles.videoWrapper} ref={videoWrapperRef}>
      <div className={mergeClasses(styles.imageWrapper, styles.fullWidth)}>
        <Image className={styles.fullWidth} src={deviceImg} />
        <DrawerOverlay
          width={videoDimensions.width}
          height={videoDimensions.height}
          scenario={scenario}
          capTriggersMinMaxVertices={capTriggerVertices}
          onUpdate={onUpdate}
        />
      </div>
    </div>
  );
};
export default VideoWrapper;
