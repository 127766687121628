import {
  makeStyles,
  TagPicker as FluentTagPicker,
  TagPickerList,
  TagPickerOption,
  TagPickerControl,
  TagPickerGroup,
  TagPickerInput,
  Tag
} from '@fluentui/react-components';

interface TagPickerProps {
  readonly allOptions: string[];
  readonly selectedOptions: string[];
  readonly onUnassign: (option: string) => void;
  readonly onAssign: (option: string) => void;
  readonly id: number;
}

const useStyles = makeStyles({
  fullWidth: {
    width: '100%'
  }
});

export const TagPicker = ({
  allOptions,
  onAssign,
  onUnassign,
  selectedOptions,
  id
}: TagPickerProps) => {
  const styles = useStyles();

  const selectableOptions = allOptions.filter(option => !selectedOptions.includes(option)).sort();

  return (
    <FluentTagPicker
      onOptionSelect={(_, data) => {
        if (data.value === 'no-options') return;
        selectedOptions.includes(data.value) ? onUnassign(data.value) : onAssign(data.value);
      }}
      selectedOptions={selectedOptions}
    >
      <TagPickerControl className={styles.fullWidth} data-testid={'tag-picker-' + id}>
        <TagPickerGroup>
          {selectedOptions.sort().map(option => (
            <Tag
              key={'tag-picker-selected-' + option}
              value={option}
              data-testid={'tag-picker-selected-' + option}
            >
              {option}
            </Tag>
          ))}
        </TagPickerGroup>
        <TagPickerInput />
      </TagPickerControl>
      <TagPickerList>
        {selectableOptions.length > 0 ? (
          selectableOptions.map(option => (
            <TagPickerOption
              value={option}
              key={'tag-picker-option-' + option}
              data-testid={'tag-picker-option-' + option}
            >
              {option}
            </TagPickerOption>
          ))
        ) : (
          <TagPickerOption value="no-options">No options available</TagPickerOption>
        )}
      </TagPickerList>
    </FluentTagPicker>
  );
};
