import {useCallback, useEffect, useState} from 'react';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

import DeviceCard, {RegisteredDevices} from './DeviceCard';
import {fetchImg} from '../../../fetcher';
import {useToken} from '../../../hooks/useToken';
import Loader from '../../Loader';
import {AxisDevice} from '../../../api/__generated__/graphql';

interface Props {
  readonly devices: Partial<AxisDevice>[];
  readonly selectedDevices: Partial<AxisDevice>[];
  readonly devicesSelectHandler: (devicesList: Partial<AxisDevice>[]) => void;
  readonly setOpenSidebar: (serial: string) => void;
}

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: tokens.spacingHorizontalL,
    ...shorthands.padding(tokens.spacingHorizontalL)
  }
});

const DevicesGridView = ({
  devices,
  selectedDevices,
  devicesSelectHandler,
  setOpenSidebar
}: Props) => {
  const styles = useStyles();
  const token = useToken();
  const {organization} = usePopulatedTopbarValues();
  const [devicesList, setDevicesList] = useState<RegisteredDevices[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setDevicesList([]);
    if (token && organization?.arn) {
      devices.forEach(dev => {
        if (dev.serial) {
          fetchImg(token, organization.arn, dev.serial).then(blobUrl => {
            if (blobUrl) {
              setDevicesList(devList => [...devList, {...dev, deviceImg: blobUrl}]);
            }
            setIsLoading(false);
          });
        }
      });
    }
  }, [devices, organization, token]);

  const setDeviceSelectState = useCallback(
    (isDeviceSelected: boolean, deviceData: RegisteredDevices) => {
      const previousSelectedDevices = selectedDevices.filter(
        selectedDev => selectedDev.serial !== deviceData.serial
      );
      const newDevice = devices.filter(
        device => isDeviceSelected && deviceData.serial === device.serial
      );
      devicesSelectHandler([...previousSelectedDevices, ...newDevice]);
    },
    [devices, devicesSelectHandler, selectedDevices]
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.main}>
      {devicesList
        .sort((a, b) => ((a.serial || '') < (b.serial || '') ? 0 : 1))
        .map(device => (
          <DeviceCard
            setOpenSidebar={setOpenSidebar}
            key={device.serial}
            device={device}
            isSelected={selectedDevices.some(dev => device.serial === dev.serial)}
            onDeviceSelect={setDeviceSelectState}
          />
        ))}
    </div>
  );
};

export default DevicesGridView;
