import {
  makeStyles,
  tokens,
  shorthands,
  mergeClasses,
  Switch,
  useId,
  Label,
  Input,
  InputProps
} from '@fluentui/react-components';
import {useCallback} from 'react';

import {
  AoACapabilities,
  AoAScenario,
  CrosslineCountingAoAScenario,
  LineDirection,
  TriggerCountingLine
} from '../../../../../types';
import ScenarioName from '../ScenarioName';
import VideoWrapper from '../VideoWrapper';
import VirtualLine from '../VirtualLine';

interface Props {
  readonly deviceImg: string;
  readonly scenario: CrosslineCountingAoAScenario;
  readonly deviceCapabilities: AoACapabilities;
  readonly updateScenarioConfiguration: (scenario: AoAScenario) => void;
}

const useStyles = makeStyles({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  configWrapper: {
    display: 'flex',
    justifyContent: 'center',
    ...shorthands.padding(tokens.spacingHorizontalXL, 0)
  },
  settingsWrapper: {
    ...shorthands.padding(0, tokens.spacingHorizontalXL),
    width: '45rem'
  },
  settingsSection: {
    ...shorthands.margin(0, 0, tokens.spacingVerticalXXXL)
  },
  settingsContent: {
    ...shorthands.padding(0, 0, 0, tokens.spacingHorizontalL),
    ...shorthands.margin(0, 0, tokens.spacingVerticalM)
  },
  controlsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& h4': {
      ...shorthands.margin(0, 0, tokens.spacingVerticalXS)
    }
  },
  countsInput: {
    width: '5rem'
  },
  fullWidth: {
    width: '100%'
  }
});

const CrosslineCountingScenarioTemplate = ({
  deviceImg,
  scenario,
  deviceCapabilities,
  updateScenarioConfiguration
}: Props) => {
  const styles = useStyles();
  const inputId = useId('countsInterval');
  const {triggers: capTriggers, scenarios: capScenarios} = deviceCapabilities;
  const {accumulatedCounts, passthroughConfiguration, eventInterval, triggers} = scenario;

  const changeTriggerDirectionHandler = useCallback(() => {
    const trigger: TriggerCountingLine = triggers[0];

    updateScenarioConfiguration({
      ...scenario,
      triggers: [
        {
          ...trigger,
          countingDirection:
            trigger?.countingDirection === LineDirection.LTR ? LineDirection.RTL : LineDirection.LTR
        }
      ]
    });
  }, [scenario, triggers, updateScenarioConfiguration]);

  const onChangePassthroughCounts: InputProps['onChange'] = (_, data) => {
    if (parseInt(data.value) <= 10000) {
      updateScenarioConfiguration({
        ...scenario,
        passthroughConfiguration: {
          ...passthroughConfiguration,
          period: parseInt(data.value)
        }
      });
    }
  };

  const onChangeEventInterval = () => {
    updateScenarioConfiguration({
      ...scenario,
      eventInterval: {enabled: !eventInterval.enabled}
    });
  };

  const onChangeAccumulatedCounts = () => {
    updateScenarioConfiguration({
      ...scenario,
      accumulatedCounts: {
        timedResetEnabled: !accumulatedCounts.timedResetEnabled
      }
    });
  };

  const onChangePassThroughThreshold = () => {
    updateScenarioConfiguration({
      ...scenario,
      passthroughConfiguration: {
        ...passthroughConfiguration,
        enabled: !passthroughConfiguration.enabled,
        ...(passthroughConfiguration.enabled && {period: 1})
      }
    });
  };

  const onResetLineHandler = () => {
    if (
      !capTriggers.countingLine.defaultInstance ||
      !capTriggers.countingLine.defaultCountingDirection
    )
      return;

    updateScenarioConfiguration({
      ...scenario,
      triggers: [
        {
          ...triggers[0],
          vertices: capTriggers.countingLine.defaultInstance,
          countingDirection: capTriggers.countingLine.defaultCountingDirection
        }
      ]
    });
  };

  return (
    <div className={mergeClasses(styles.wrapper, styles.fullWidth)}>
      <ScenarioName
        minLengthName={capScenarios.minLengthName || undefined}
        maxLengthName={capScenarios.maxLengthName || undefined}
        currentScenarioName={scenario.name}
        onUpdate={name => updateScenarioConfiguration({...scenario, name})}
      />
      <div className={mergeClasses(styles.configWrapper, styles.fullWidth)}>
        <VideoWrapper
          deviceImg={deviceImg}
          scenario={scenario}
          capTriggerVertices={capTriggers.lineCrossing}
          onUpdate={scenario => 'id' in scenario && updateScenarioConfiguration(scenario)}
        />
        <div className={styles.settingsWrapper}>
          <VirtualLine
            changeTriggerDirection={changeTriggerDirectionHandler}
            onResetLine={onResetLineHandler}
          />
          <h3>Counting settings</h3>
          <div className={styles.settingsSection}>
            <Switch
              checked={eventInterval.enabled}
              label={
                <div>
                  <h4>Event interval</h4> Send events with counting data at one-minute intervals.
                </div>
              }
              labelPosition="before"
              className={styles.controlsContainer}
              onChange={onChangeEventInterval}
            />
          </div>
          <div className={styles.settingsSection}>
            <Switch
              checked={accumulatedCounts.timedResetEnabled}
              label={
                <div>
                  <h4>Reset counts at midnight</h4>
                </div>
              }
              labelPosition="before"
              className={styles.controlsContainer}
              onChange={onChangeAccumulatedCounts}
            />
          </div>
          <div className={styles.settingsSection}>
            <Switch
              checked={passthroughConfiguration.enabled}
              label={
                <div>
                  <h4>Passthrough threshold</h4>
                </div>
              }
              labelPosition="before"
              className={styles.controlsContainer}
              onChange={onChangePassThroughThreshold}
            />
            <div className={mergeClasses(styles.settingsContent, styles.controlsContainer)}>
              <Label htmlFor={inputId}>Controlled input limiting the value to 20 characters</Label>
              <Input
                disabled={!passthroughConfiguration.enabled}
                className={styles.countsInput}
                defaultValue="1"
                value={passthroughConfiguration.period.toString()}
                onChange={onChangePassthroughCounts}
                id={inputId}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrosslineCountingScenarioTemplate;
