import {createSlice} from '@reduxjs/toolkit';

// Define a type for the slice state
interface IResourceGroups {
  readonly selectedResourceGroup: {id: string; name: string};
}

// Define the initial state using that type
const initialState: IResourceGroups = {
  selectedResourceGroup: {id: '', name: ''}
};

export const resourceGroupsSlice = createSlice({
  name: 'resourceGroups',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedResourceGroup: (state, action) => {
      state.selectedResourceGroup = action.payload;
    }
  }
});

export const {setSelectedResourceGroup} = resourceGroupsSlice.actions;
export default resourceGroupsSlice.reducer;
