import React, {useMemo} from 'react';
import {CoordArray} from 'media-overlay-library';

import Flexigon from '../Flexigon';
import {FilterExcludeArea, TriggerMotion} from '../../../types';
import {makeStyles} from '@fluentui/react-components';

interface ExcludeOverlayProps {
  readonly include: CoordArray;
  readonly exclude: CoordArray[];
  readonly width: number;
  readonly height: number;
}

const useStyles = makeStyles({
  background: {
    fill: '#ffffff'
  },
  includePath: {
    fill: '#000000'
  },
  excludePath: {
    fill: '#ffffff'
  },
  maskFill: {
    fill: 'rgba(0, 0, 0, 0.4)',
    pointerEvents: 'none'
  }
});

const verticesToPath = (vertices: CoordArray) => {
  return `M ${vertices.map(([x, y]) => `${x},${y}`).join(' L ')} Z`;
};

const ExcludeOverlayMask = ({include, exclude, width, height}: ExcludeOverlayProps) => {
  const styles = useStyles();

  return (
    <React.Fragment>
      <mask id="myMask">
        <rect className={styles.background} width={width} height={height} />
        {include.length && <path className={styles.includePath} d={verticesToPath(include)} />}
        {exclude.map(
          (vertices, index) =>
            vertices.length > 0 && (
              <path className={styles.excludePath} key={index} d={verticesToPath(vertices)} />
            )
        )}
      </mask>
      <rect className={styles.maskFill} width={width} height={height} mask="url(#myMask)" />
    </React.Fragment>
  );
};

interface IOverlayProps {
  readonly trigger: TriggerMotion;
  readonly excludeAreas: FilterExcludeArea[];
  readonly minNbrVertices?: number;
  readonly maxNbrVertices?: number;
  readonly width: number;
  readonly height: number;
  readonly onUpdateIncludeArea: (vertices: CoordArray) => void;
  readonly onUpdateExcludeArea: (
    vertices: ReadonlyArray<readonly [number, number]>,
    data: CoordArray
  ) => void;
}

const ExcludeOverlay = ({
  trigger,
  excludeAreas,
  minNbrVertices,
  maxNbrVertices,
  width,
  height,
  onUpdateIncludeArea,
  onUpdateExcludeArea
}: IOverlayProps) => {
  const [includeVertices, setIncludeVertices] = React.useState<CoordArray>(trigger.vertices);
  const [excludeVertices, setExcludeVertices] = React.useState<CoordArray[]>(
    excludeAreas.map(excludeArea => excludeArea.vertices)
  );

  const flexigons = useMemo(
    () => (
      <React.Fragment>
        <Flexigon
          points={trigger.vertices}
          className="includeArea"
          {...(minNbrVertices && {minNumberOfPoints: minNbrVertices})}
          {...(maxNbrVertices && {maxNumberOfPoints: maxNbrVertices})}
          onUpdate={onUpdateIncludeArea}
          onDrag={setIncludeVertices}
        />
        {excludeAreas.map((excludeArea, index) => (
          <Flexigon
            key={`${excludeArea.vertices.toString()}-${index}`}
            className={`excludeArea-${index}`}
            points={excludeArea.vertices}
            {...(minNbrVertices && {minNumberOfPoints: minNbrVertices})}
            {...(minNbrVertices && {maxNumberOfPoints: maxNbrVertices})}
            onUpdate={data => onUpdateExcludeArea(excludeArea.vertices, data)}
            onDrag={polygon =>
              setExcludeVertices(polygons => [
                ...polygons.slice(0, index),
                polygon,
                ...polygons.slice(index + 1)
              ])
            }
          />
        ))}
      </React.Fragment>
    ),
    [
      trigger,
      excludeAreas,
      minNbrVertices,
      maxNbrVertices,
      onUpdateIncludeArea,
      onUpdateExcludeArea
    ]
  );

  return (
    <React.Fragment>
      <ExcludeOverlayMask
        include={includeVertices}
        exclude={excludeVertices}
        width={width}
        height={height}
      />
      {flexigons}
    </React.Fragment>
  );
};

export default ExcludeOverlay;
