import {
  CrosslineCountingAoAScenario,
  Device,
  FilterTypes,
  LineCrossingAoAScenario,
  LineDirection,
  MotionInAreaScenario,
  OccupancyInAreaScenario,
  ScenarioType,
  TriggerLineCrossing,
  TriggerMotion,
  TriggerTypes
} from '../../../../types';

interface AoAScenarioBaseTemplate {
  readonly id: number;
  readonly name: string;
  readonly type: ScenarioType;
  devices: [{id: Device['id']; type?: 'camera'}];
  objectClassifications: [];
  metadataOverlay: null;
  triggers: [];
  filters: [];
}

const virtualLineDefaultVertices: TriggerLineCrossing['vertices'] = [
  [0, -0.7],
  [0, 0.7]
];

const includeAreaDefaultVertices: TriggerMotion['vertices'] = [
  [-0.97, -0.97],
  [-0.97, 0.97],
  [0.97, 0.97],
  [0.97, -0.97]
];

const aoaScenarioBase: AoAScenarioBaseTemplate = {
  id: 1,
  name: '',
  type: ScenarioType.MOTION_IN_AREA,
  devices: [
    {
      id: 1
    }
  ],
  objectClassifications: [],
  metadataOverlay: null,
  triggers: [],
  filters: []
};

export const motionInAreaTemplate: MotionInAreaScenario = {
  /** @todo: adjust or add more to this scenario? */
  ...aoaScenarioBase,
  type: ScenarioType.MOTION_IN_AREA,
  filters: [
    {
      time: 1,
      type: FilterTypes.SHORT_LIVED_FILTER
    },
    {
      distance: 3,
      type: FilterTypes.SWAYING_OBJECT_FILTER
    },
    {
      height: 3,
      type: FilterTypes.SIZE_PERCENTAGE_FILTER,
      width: 3
    }
  ],
  triggers: [
    {
      type: TriggerTypes.INCLUDE_AREA,
      vertices: includeAreaDefaultVertices
    }
  ]
};

export const aoaLinecrossingScenarioTemplate: LineCrossingAoAScenario = {
  ...aoaScenarioBase,
  type: ScenarioType.LINE_CROSSING,
  filters: [
    {width: 3, height: 3, type: FilterTypes.SIZE_PERCENTAGE_FILTER},
    {time: 1, type: FilterTypes.SHORT_LIVED_FILTER}
  ],
  triggers: [
    {
      type: TriggerTypes.LINE_CROSSING,
      vertices: virtualLineDefaultVertices,
      alarmDirection: LineDirection.LTR
    }
  ]
};

export const aoaCrosslineCountingScenarioTemplate: CrosslineCountingAoAScenario = {
  ...aoaScenarioBase,
  type: ScenarioType.CROSSLINE_COUNTING,
  triggers: [
    {
      type: TriggerTypes.COUNTING_LINE,
      vertices: virtualLineDefaultVertices,
      countingDirection: LineDirection.LTR
    }
  ],
  accumulatedCounts: {
    timedResetEnabled: false
  },
  passthroughConfiguration: {
    enabled: false,
    period: 1
  },
  eventInterval: {
    enabled: false
  }
};

export const occupancyInAreaTemplate: OccupancyInAreaScenario = {
  ...aoaScenarioBase,
  type: ScenarioType.OCCUPANCY_IN_AREA,
  triggers: [
    {
      type: TriggerTypes.INCLUDE_AREA,
      vertices: includeAreaDefaultVertices
    }
  ],
  thresholdConfiguration: {
    enabled: false,
    triggerDelay: 0,
    thresholds: [
      {
        level: 0,
        type: 'moreThan'
      }
    ]
  },
  eventInterval: {
    enabled: false
  }
};
