import {PropsWithChildren} from 'react';

import {makeStyles, shorthands, Subtitle1, tokens} from '@fluentui/react-components';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '44px', // fit medium-sized horizontal TabList inside
    display: 'flex',
    alignItems: 'center',
    ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralStroke3),
    ...shorthands.overflow('hidden'),
    isolation: 'isolate',
    '& > span': {
      fontFamily: 'Lubalin'
    }
  },
  point: {
    color: tokens.colorBrandBackground
  },
  content: {
    ...shorthands.flex(1, 0, 'auto'),
    alignSelf: 'start',
    marginInlineStart: tokens.spacingHorizontalXXXL,
    paddingInlineEnd: tokens.spacingHorizontalXXL,
    ...shorthands.overflow('hidden')
  }
});

interface PageHeaderProps {
  readonly title: string;
}

const PageHeader = ({children, title}: PropsWithChildren<PageHeaderProps>) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Subtitle1 truncate wrap={false}>
        {title}
      </Subtitle1>
      <Subtitle1 className={styles.point}>.</Subtitle1>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default PageHeader;
