import {
  Button,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@fluentui/react-components';

interface ModalProps {
  readonly onConfigure: () => void;
}

const ConfigureConfirmationModal = ({onConfigure}: ModalProps) => {
  return (
    <Dialog>
      <DialogTrigger disableButtonEnhancement>
        <Button appearance="primary">Configure</Button>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Configure all scenarios</DialogTitle>
          <DialogContent>
            Once confirmed, the scenarios for each of the devices will be created and you can set
            event rules. Would you like to continue?
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">No</Button>
            </DialogTrigger>
            <Button appearance="primary" onClick={onConfigure}>
              Yes
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default ConfigureConfirmationModal;
