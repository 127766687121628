import {useState, useEffect} from 'react';

/**
 * A hook that provides an id and translation vector
 * for an element that has called a dragStart event.
 * @param onDragEnd A dragEnd callback with id and translation vector
 */
const useDraggable = (
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDragEnd: (id: any | null, t: number[]) => void
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
): [any | null, number[], (e: React.PointerEvent, eId: any, _lockX?: boolean) => void] => {
  const [lockX, setLockX] = useState(false);
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [id, setId] = useState<any | null>(null);
  const [m0, setMouse0] = useState<[number, number] | null>(null);
  const [t, setTranslation] = useState([0, 0]);

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDragStart = (e: React.PointerEvent, eId: any, _lockX = false) => {
    e.stopPropagation();
    setLockX(_lockX);
    setMouse0([e.pageX, e.pageY]);
    setId(eId);
  };

  useEffect(() => {
    if (m0) {
      const [mx, my] = m0;
      const updateMouse = (e: {pageX: number; pageY: number}) =>
        setTranslation([lockX ? 0 : e.pageX - mx, e.pageY - my]);
      const dragEnd = () => onDragEnd(id, t);
      document.addEventListener('pointermove', updateMouse);
      document.addEventListener('pointerup', dragEnd);

      return () => {
        document.removeEventListener('pointermove', updateMouse);
        document.removeEventListener('pointerup', dragEnd);
      };
    }
  }, [m0, t, lockX, id, onDragEnd]);

  return [id, t, onDragStart];
};

export default useDraggable;
