import React, {useCallback} from 'react';

interface PointProps extends React.SVGAttributes<SVGGElement> {
  readonly id: string;
  readonly cx: number;
  readonly cy: number;
  readonly onPointerDown: (event: React.PointerEvent) => void;
  readonly onRightClick: (id: string) => void;
}

interface GhostPointProps extends React.SVGAttributes<SVGGElement> {
  readonly cx: number;
  readonly cy: number;
  readonly onPointerDown: (event: React.PointerEvent) => void;
}

const Point = ({id, cx, cy, onPointerDown, onRightClick, ...rest}: PointProps) => {
  const handlePointerDown = useCallback(
    (event: React.PointerEvent) => {
      if (event.button === 2) {
        onRightClick(id);
      } else {
        onPointerDown(event);
      }
    },
    [id, onPointerDown, onRightClick]
  );

  const preventContextMenu = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
  }, []);

  return (
    <g onPointerDown={handlePointerDown} onContextMenu={preventContextMenu} {...rest}>
      <circle r={10} cx={cx} cy={cy} className="outer" />
      <circle r={5} cx={cx} cy={cy} className="inner" />
    </g>
  );
};

const GhostPoint = ({cx, cy, onPointerDown, ...rest}: GhostPointProps) => {
  const handlePointerDown = useCallback(
    (event: React.PointerEvent) => {
      if (event.button !== 2) {
        onPointerDown(event);
      }
    },
    [onPointerDown]
  );

  const preventContextMenu = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
  }, []);

  return (
    <g onPointerDown={handlePointerDown} onContextMenu={preventContextMenu} {...rest}>
      <circle r={10} cx={cx} cy={cy} className="outer" />
      <circle r={3} cx={cx} cy={cy} className="inner" />
    </g>
  );
};

export {Point, GhostPoint};
