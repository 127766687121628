import {Foundation, Area} from 'media-overlay-library';

interface Props {
  readonly children: React.ReactNode;
}

const USER_BASIS: Area = [
  [-1, 1], // top left coordinate
  [1, -1] // bottom right coordinate
];

const FoundationLayer = ({children}: Props) => {
  return (
    <Foundation
      userBasis={USER_BASIS}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: '0',
        top: '0',
        right: '0',
        bottom: '0'
      }}
    >
      {children}
    </Foundation>
  );
};

export default FoundationLayer;
