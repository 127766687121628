import {
  Checkbox,
  CheckboxProps,
  Field,
  Input,
  InputProps,
  Label,
  makeStyles,
  shorthands,
  tokens
} from '@fluentui/react-components';
import {useEffect, useRef, useState} from 'react';
import {addEventRule, DeviceDetailsState} from '../../../../reducers/deviceDetailsSlice';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';

interface RuleCondition {
  serial: string;
  name: string;
  selected: boolean;
  scenarioID: number;
}

const useStyles = makeStyles({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.padding(tokens.spacingVerticalXL, 0)
  },
  formWrapper: {
    width: '30rem',
    ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingVerticalM)
  },
  section: {
    ...shorthands.padding(tokens.spacingVerticalM, 0)
  },
  controlsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: tokens.spacingVerticalXL,
    paddingLeft: tokens.spacingHorizontalS
  },
  conditionsWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  conditionsOption: {
    alignItems: 'center',
    colomnGap: tokens.spacingHorizontalL,
    display: 'flex',
    '& span': {
      marginRight: tokens.spacingHorizontalM
    }
  }
});

const SetEvents = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const {devicesAoADetails, eventRule, devicesGeneralDetails}: DeviceDetailsState = useAppSelector(
    state => state.deviceDetailsSlice
  );

  const [eventRuleConfig, setEventRuleConfig] = useState({
    actionId: 1 + Date.now(),
    ruleName: '',
    waitTime: '0',
    videoChannel: 'View area 1',
    overlayText: '',
    ruleConditions: [] as RuleCondition[]
  });

  const isOnlyCondition =
    eventRuleConfig.ruleConditions.filter(condition => condition.selected).length === 1;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!eventRuleConfig.ruleConditions.length) {
      const scenarioRuleConditions = devicesGeneralDetails
        .map(generalDetails => {
          const aoaDeviceDetails = devicesAoADetails.find(
            details => details.serial === generalDetails.serial
          );
          const newScenario = aoaDeviceDetails?.configuration.scenarios.at(-1);
          if (!newScenario) {
            return null;
          }

          return {
            serial: generalDetails.serial,
            name: newScenario.name,
            selected: true,
            scenarioID: newScenario.id
          };
        })
        .filter((rule): rule is RuleCondition => rule !== null);

      setEventRuleConfig({
        ...eventRuleConfig,
        ruleConditions: scenarioRuleConditions
      });
    }
  }, [eventRuleConfig, devicesGeneralDetails, devicesAoADetails]);

  const onSetWaitTime: InputProps['onChange'] = (_, data) => {
    setEventRuleConfig({...eventRuleConfig, waitTime: data.value});
  };

  const onChangeCondition: CheckboxProps['onChange'] = (e, data) => {
    const conditionsCopy = [...eventRuleConfig.ruleConditions];
    const idx = conditionsCopy.findIndex(condition => condition.serial === e.target.value);
    if (idx >= 0) {
      conditionsCopy[idx] = {
        ...conditionsCopy[idx],
        selected: data.checked
      } as RuleCondition;
    }

    setEventRuleConfig({
      ...eventRuleConfig,
      ruleConditions: conditionsCopy
    });
    dispatch(addEventRule({ruleConditions: conditionsCopy}));
  };
  const onSetRuleName: InputProps['onChange'] = (ev, data) => {
    setEventRuleConfig({...eventRuleConfig, ruleName: data.value});
  };

  const onSetVideoChannel: InputProps['onChange'] = (_, data) => {
    setEventRuleConfig({...eventRuleConfig, videoChannel: data.value});
  };

  const onSetOverlayText: InputProps['onChange'] = (_, data) => {
    setEventRuleConfig({...eventRuleConfig, overlayText: data.value});
  };

  const setFormValues = () => {
    dispatch(addEventRule({...eventRuleConfig}));
  };

  return (
    <div className={styles.wrapper}>
      <h3>Add events</h3>
      <div className={styles.formWrapper}>
        <div className={styles.section}>
          <h4>Add rule</h4>
          <div className={styles.controlsContainer}>
            <Field
              label="Name"
              required
              {...(!eventRule.isValid &&
                eventRule.ruleName === '' && {validationMessage: 'This field is empty..'})}
            >
              <Input
                ref={inputRef}
                appearance="underline"
                size="large"
                id="ruleName"
                value={eventRuleConfig.ruleName}
                onChange={onSetRuleName}
                onBlur={setFormValues}
              />
            </Field>
          </div>
          <div className={styles.controlsContainer}>
            <Label>Wait between actions in seconds</Label>
            <Input
              appearance="underline"
              size="large"
              id="waitTime"
              defaultValue="0"
              onChange={onSetWaitTime}
              onBlur={setFormValues}
            />
          </div>
          <div className={styles.section}>
            <h4>Set condition for</h4>
            <div className={styles.conditionsWrapper}>
              {eventRuleConfig.ruleConditions.map((condition, index) => (
                <Checkbox
                  key={index}
                  value={condition.serial}
                  checked={condition.selected}
                  disabled={isOnlyCondition && condition.selected}
                  label={
                    <div className={styles.conditionsOption}>
                      <span>{condition.serial}</span>
                      <span>{condition.name}</span>
                    </div>
                  }
                  onChange={onChangeCondition}
                />
              ))}
            </div>
          </div>
          <div className={styles.section}>
            <h4>Action </h4>
            <div className={styles.controlsContainer}>
              <Checkbox defaultChecked disabled label="Use overlay text while rule is active" />
            </div>
            <div className={styles.controlsContainer}>
              <Field label="Video channel" required>
                <Input
                  appearance="underline"
                  size="medium"
                  id="videoChannel"
                  defaultValue="1"
                  onChange={onSetVideoChannel}
                  onBlur={setFormValues}
                />
              </Field>
            </div>
            <div className={styles.controlsContainer}>
              <Field
                required
                label="Overlay text"
                hint="Before you can see the overlay text you need to add an overlay with '#D' modifier."
                {...(!eventRule.isValid &&
                  eventRule.overlayText === '' && {
                    validationMessage: 'This field is empty.'
                  })}
              >
                <Input
                  appearance="underline"
                  size="large"
                  id="overlayText"
                  value={eventRuleConfig.overlayText}
                  onChange={onSetOverlayText}
                  onBlur={setFormValues}
                />
              </Field>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetEvents;
