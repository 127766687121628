const arraysAreEqual = (arr1: readonly unknown[], arr2: readonly unknown[]) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      if (obj1 !== obj2) {
        return false;
      }
    } else {
      for (const key in obj1) {
        if (Array.isArray(obj1[key])) {
          if (!arraysAreEqual(obj1[key], obj2[key])) {
            return false;
          }
        } else {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
      }
    }
  }

  return true;
};

export default arraysAreEqual;
