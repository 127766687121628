import {PropsWithChildren} from 'react';

import {Provider as GraphQLProvider} from 'urql';
import {createClient, cacheExchange, fetchExchange} from 'urql';

import {appConfig} from '../config';
import {refresh} from '@axteams-one/auth-provider';
import {authExchange} from '@urql/exchange-auth';

export const createGraphQLClient = () =>
  createClient({
    url: `${appConfig.acxURL}?acx-client-key=${appConfig.acxApiKey}`,
    exchanges: [
      cacheExchange,
      authExchange(async utilities => {
        let token = await refresh().then(resp => resp.data);

        return {
          addAuthToOperation(operation) {
            return utilities.appendHeaders(operation, {
              Authorization: `Bearer ${token?.access_token}`
            });
          },
          didAuthError(error) {
            return error.response.status === 401;
          },
          async refreshAuth() {
            token = await refresh().then(resp => resp.data);
          }
        };
      }),
      fetchExchange
    ]
  });

const ApiProvider = ({children}: PropsWithChildren) => {
  const client = createGraphQLClient();

  return <GraphQLProvider value={client}>{children}</GraphQLProvider>;
};

export default ApiProvider;
