import {Input, InputProps, makeStyles} from '@fluentui/react-components';
import {Edit24Regular} from '@fluentui/react-icons';
import {tokens} from '@fluentui/tokens';
import {shorthands} from '@griffel/core';
import {useEffect, useState} from 'react';

interface Props {
  readonly currentScenarioName: string;
  readonly minLengthName?: number;
  readonly maxLengthName?: number;
  readonly onUpdate: (name: string) => void;
}

const useStyles = makeStyles({
  nameInput: {
    fontWeight: '600',
    width: '15rem',
    ...shorthands.margin(tokens.spacingVerticalL, 0)
  }
});

const ScenarioName = ({
  currentScenarioName,
  minLengthName = 1,
  maxLengthName = 15,
  onUpdate
}: Props) => {
  const styles = useStyles();
  const [scenarioName, setScenarioName] = useState<string>(currentScenarioName);

  useEffect(() => {
    setScenarioName(currentScenarioName);
  }, [currentScenarioName]);

  const onNameChange: InputProps['onChange'] = (_, data) => {
    if (data.value.length <= maxLengthName) {
      setScenarioName(data.value);
    }
  };

  const updateScenarioName = () => {
    if (scenarioName) {
      const editedName = scenarioName.trim();
      if (
        currentScenarioName &&
        editedName.length &&
        currentScenarioName !== editedName &&
        editedName.length >= minLengthName &&
        editedName.length <= maxLengthName
      ) {
        onUpdate(editedName);
      }
    } else {
      setScenarioName(currentScenarioName);
    }
  };

  return (
    <Input
      size="large"
      appearance="underline"
      className={styles.nameInput}
      value={scenarioName}
      contentAfter={<Edit24Regular />}
      onChange={onNameChange}
      onBlur={updateScenarioName}
    />
  );
};

export default ScenarioName;
