import {PropsWithChildren} from 'react';
import {
  getTraceContextCarrier,
  makeOpenTelemetryWeb,
  OpenTelemetryProvider
} from '@axteams-one/opentelemetry-js-react';
import {appConfig} from './config';

const openTelemetry = makeOpenTelemetryWeb({
  serviceName: 'data-insights-dashboard-fe',
  serviceVersion: process.env.REACT_APP_DD_VERSION,
  owner: 'owner:bva3',
  environment: appConfig.env,
  collector: appConfig.env === 'prod' ? 'prod' : 'stage',
  traceContextCarrier: getTraceContextCarrier()
});

export function DidOpenTelemetryProvider({children}: PropsWithChildren) {
  return <OpenTelemetryProvider openTelemetry={openTelemetry}>{children}</OpenTelemetryProvider>;
}
