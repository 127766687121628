import {Dispatch, SetStateAction} from 'react';

export default class PromiseQueue {
  queue: (() => Promise<void>)[] = [];
  running = false;
  enqueue(promise: () => Promise<void>, setQueueLength: Dispatch<SetStateAction<number>>) {
    this.queue.push(promise);
    this.dequeue(setQueueLength);
  }
  dequeue(setQueueLength: Dispatch<SetStateAction<number>>) {
    setQueueLength(this.queue.length);
    if (this.running) {
      return;
    }
    const promise = this.queue.shift();
    if (!promise) {
      return;
    }
    this.running = true;
    promise().then(() => {
      this.running = false;
      this.dequeue(setQueueLength);
    });
  }
}
