import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import PageHeader from '../../components/PageHeader';
import {useAppSelector} from '../../store/hooks';
import PageContent from '../../components/PageContent';
import OnboardingCard from './OnboardingCard';
import CreateResourceGroupCard from './CreateResourceGroupCard';
import RemoveResourceGroupCard from './RemoveResourceGroupCard';
import {DeviceTable} from './DeviceTable';
import NoValidOrganization from '../../components/NoValidOrganization';

const OrganizationManagement = () => {
  const {organization, loaded} = usePopulatedTopbarValues();
  const {selectedResourceGroup} = useAppSelector(state => state.resourceGroupsSlice);

  if (!organization) {
    return <NoValidOrganization loaded={loaded} />;
  }

  return (
    <PageContent showResourceGroupTree>
      <PageHeader title={selectedResourceGroup.name} />
      <OnboardingCard />
      <CreateResourceGroupCard />
      <RemoveResourceGroupCard />
      <DeviceTable />
    </PageContent>
  );
};

export default OrganizationManagement;
