import {makeStyles, tokens, Image, mergeClasses} from '@fluentui/react-components';
import GenericDevice from '../svg/GenericDevice';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalM
  },
  disabled: {
    opacity: 0.5
  }
});

interface DeviceModelImageProps {
  piaId?: string;
  disabled?: boolean;
}

export const DeviceModelImage = ({piaId, disabled}: DeviceModelImageProps) => {
  const styles = useStyles();
  return (
    <div className={disabled ? mergeClasses(styles.disabled, styles.container) : styles.container}>
      {piaId ? <Image src={productImageUrl(piaId)} /> : <GenericDevice />}
    </div>
  );
};

function productImageUrl(piaId: string, size: 40 | 78 | 512 = 40) {
  return `https://www.axis.com/images/products/${piaId}-${size}.png`;
}
