import {AuthStatus, refresh} from '@axteams-one/auth-provider';

/**
 * Returns valid access token.
 */
export async function getAccessToken() {
  const rsp = await refresh();
  if (rsp.status !== AuthStatus.Authenticated) {
    console.error('failed to get valid token: not authenticated');
    return '';
  }
  if (rsp.data === undefined) {
    console.error('failed to get valid token: no data');
    return '';
  }
  return rsp.data.access_token;
}
