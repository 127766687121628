import {MainEmptyView} from '@axiscommunications/fluent-empty-view';
import Loader from '../views/Loader';

interface NoValidOrganizationProps {
  loaded: boolean;
}
const NoValidOrganization = ({loaded}: NoValidOrganizationProps) => {
  if (!loaded) {
    return <Loader />;
  }
  return <MainEmptyView illustration="empty-space" title="No organization selected" />;
};

export default NoValidOrganization;
