import {Coord, CoordArray, select, modulo} from 'media-overlay-library';

const translateFlexStructure =
  (pointClamp: (point: Coord) => Coord, areaClamp: (points: CoordArray) => CoordArray) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (points: CoordArray, id: any, t: number[]) => {
    const [tx, ty] = t;
    if (id === null) {
      // nothing is translated
      return points;
    }

    if (id === 'FLEX_STRUCTURE') {
      // The entire group of points is translated
      return areaClamp(
        points.map(([x, y]) => {
          return [x + tx, y + ty];
        })
      );
    }
    // A specific point is translated, id is a number (!)
    const [px, py] = points[id];
    const newPoints = points.slice();
    newPoints[id] = pointClamp([px + tx, py + ty]);

    // recompute true half translation after clamping
    const tx2 = (newPoints[id][0] - px) / 2;
    const ty2 = (newPoints[id][1] - py) / 2;

    // determine mid-points if we're moving a point
    if (typeof id === 'number' && id % 2 === 0) {
      const a = select(newPoints, id - 1);
      const b = select(newPoints, id + 1);
      newPoints[modulo(id - 1, newPoints.length)] = [a[0] + tx2, a[1] + ty2];
      newPoints[modulo(id + 1, newPoints.length)] = [b[0] + tx2, b[1] + ty2];
    }
    return newPoints;
  };

export default translateFlexStructure;
