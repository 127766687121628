import {createSlice} from '@reduxjs/toolkit';

interface Error {
  readonly id: string;
  readonly message: string;
  readonly type: string;
}

interface Errors {
  readonly errors: Error[];
}

const errorsInitialState: Errors = {
  errors: []
};

export const errorsSlice = createSlice({
  name: 'errors',
  initialState: errorsInitialState,
  reducers: {
    addErrors: (state, action) => {
      state.errors = [...action.payload];
    }
  }
});

export const {addErrors} = errorsSlice.actions;
export default errorsSlice.reducer;
