import {shorthands, tokens, makeStyles, Button, mergeClasses} from '@fluentui/react-components';

interface Props {
  readonly onResetLine: () => void;
  readonly changeTriggerDirection: () => void;
}

const useStyles = makeStyles({
  settingsContent: {
    ...shorthands.padding(0, 0, 0, tokens.spacingHorizontalL),
    ...shorthands.margin(0, 0, tokens.spacingVerticalM)
  },
  settingsSection: {
    ...shorthands.margin(0, 0, tokens.spacingVerticalXXXL)
  },
  subWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    ...shorthands.gap(tokens.spacingHorizontalM),
    ...shorthands.margin(0, tokens.spacingHorizontalM, tokens.spacingVerticalM, 0)
  }
});

const VirtualLine = ({changeTriggerDirection, onResetLine}: Props) => {
  const styles = useStyles();

  return (
    <div>
      <h3>Virtual line </h3>
      <div className={styles.settingsSection}>
        <div className={mergeClasses(styles.settingsContent, styles.subWrapper)}>
          <Button appearance="primary" onClick={changeTriggerDirection}>
            Change trigger direction
          </Button>
          <Button appearance="primary" onClick={onResetLine}>
            Reset line
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VirtualLine;
